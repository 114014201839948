(function () {

    /*----------[Module Configuration]----------*/

    var config = {
        targets: {
            toTopBtn: '.js-prodWidgetHeadToTop',
            scrollToWidget: '.js-prodWidgetScrollTo'
        }
    };

    /*----------[Module Objects]----------*/

    var ProductWidget = flx.productWidget = {

        init: function init () {

            ProductWidget.bindToTop();
            ProductWidget.bindWidgetScroll();

        },

        bindToTop: function bindToTop () {

            $(document).on(
                'click.productWidgetToTop',
                config.targets.toTopBtn,
                ProductWidget.scrollToTopWithEvent
            );

        },

        bindWidgetScroll: function bindWidgetScroll () {

            $(document).on(
                'click.productWidgetAnchorScroll',
                config.targets.scrollToWidget,
                ProductWidget.scrollToWidget
            );

        },

        scrollToTopWithEvent: function scrollToTopWithEvent (e) {

            e.preventDefault();
            flx.util.scrollToTop();

        },

        scrollToWidget: function scrollToWidget (e) {

            e.preventDefault();

            var $targetWidget;
            var $this = $(this);
            var widgetTarget = ProductWidget.extractWidgetTarget($this);

            if (flx.util.isNotEmpty(widgetTarget)) {
                $targetWidget = $('.js-widgetTarget-' + widgetTarget).eq(0);
            }

            flx.util.scrollToElem($targetWidget);

        },

        extractWidgetTarget: function extractWidgetTarget ($this) {

            var widgetTarget = $this.attr('data-widget-target');
            return widgetTarget;

        }

    };

    /*----------[Module Main]----------*/

    // Initialize the module
    ProductWidget.init();

})();
