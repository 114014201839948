(function () {

    // jscs:disable
    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    var fetchMenu = function (isRetry) {
        var defOpts = { headers: { 'X-Requested-With': 'XMLHttpRequest' }, credentials: 'same-origin' };
        var $megaMenu = $('#mega-menu');
        return fetch('/api/mega-menu', defOpts)
            .then(function (resp) {
                if (resp.ok) {
                    return resp.text();
                } else {
                    throw resp;
                }
            })
            .then(function (mm) {
                $megaMenu.replaceWith(mm);
            })
            .catch(function (err) {

                /**
                 * This API has a tendency to fail sporadically. In case this happens, retry one more time.
                 * On success: Business as usual.
                 * On second fail: Log error and stop everything.
                 */

                if (!isRetry) {

                    // Retry the request once if it failed
                    setTimeout(function () {
                        fetchMenu(true);
                    }, 500);

                } else {

                    // Already retried... notify of failure, hide the container and abort
                    if (window.console) {
                        console.error('Mega menu error:', err);
                    }

                    // Hide the gray menu container
                    $megaMenu.parent().hide();

                    return false;

                }

            });
    };


    /*----------[Vars]----------*/
    $(document).ready(function () {
        fetchMenu().then(function () {
            // Config
            var config = {
                sizing: {
                    columnWidth: 400,
                    pgMaxWidth: 900
                }
            };

            config.autoExpand = {
                breakpoint: config.sizing.pgMaxWidth + config.sizing.columnWidth,
                debounceInterval: 150
            };

            // Init Vars
            var rowSelector = '.catLink';
            var mmOpenClass = 'mmOpen';
            var panelOpenClass = 'panelOpen';
            var rowActiveClass = 'active';
            var isTablet = $('body').hasClass('tablet');
            var isDesktop = $('body').hasClass('desktop');

            // jQ Selectors
            var $mmWrap = $('#globalHeader').find('.mmOpenLink');
            var $mmOpenBtn = $mmWrap.find('.mmOpenAnchor');
            var $mm = $mmWrap.find('.mmDropdown');
            var $mmCat = $mm.find('.catLinks');
            var $mmPanel = $mm.find('.subcatPanel');
            var $mmSvgArrow = $mmWrap.find('.svgNavDownArrow');
            var $mmRows = $mm.find(rowSelector);
            var $mmHoverables = $($mmWrap).add($mm);


            /*----------[Open/Close Functionality]----------*/

            // If on desktop, use mouseenter/mouseleave
            if (isDesktop) {
                bindMmHover();
                bindEnterOpen();
                bindPanelClose();
            } else {
                bindTouchOpen();
            }

            /**
             * Debounced window resize handler for mm expansion
             *
             * NOTES:
             *     - Calls autoExpandMm on every resize evt (debounced to configured ms timer)
             *     - Enables document-size recheck on-demand and decides whether to expand or not
             */
            function bindMmExpander () {
                $(window).on('resize.mmExpand', flx.util.debounce(
                    autoExpandMm,
                    config.autoExpand.debounceInterval
                ));
            }

            /**
             * Checks document width and chooses to either
             * allow user to open / close mm OR auto-expands
             * based on document width
             *
             * NOTES:
             *     - Checks document width against configured
             *       breakpoint for auto-expanding the mm
             *     - Conditionally binds mm hover handlers
             *       vs. always-expanded mm w/ (only) panel collapse
             */
            function autoExpandMm () {

                var docWidth = $(document).width();

                if (docWidth > config.autoExpand.breakpoint && window.location.pathname === '/') {
                    unbindMmHover();
                    openMm();
                } else {
                    bindMmHover();
                    bindEnterOpen();
                    closeMm();
                }

            }

            /**
             * Binds the hover handlers (mouseenter / mouseleave) for mm open / close
             *
             * NOTES:
             *     - This is enabled when the doc-size isn't big enough to auto-expand mm
             *     - Opens / closes based on mouseenter / mouseleave evts
             */
            function bindMmHover () {
                $mmWrap.on({
                    'mouseenter.mmHoverOpen': openMm,
                    'mouseleave.mmHoverClose': closeMm
                });
            }

            /**
             * Unbinds the hover handlers (mouseenter / mouseleave) for mm open / close
             *
             * NOTES:
             *     - This gets called when the panel is auto-expanded based on device width
             *     - This is because we want to keep the mm open at all times when auto-expanded
             */
            function unbindMmHover () {
                $mmWrap.off('mouseenter.mmHoverOpen');
                $mmWrap.off('mouseleave.mmHoverClose');
            }

            /**
             * Binds (OPEN) touch handler for tablet (modified desktop) view
             *
             * NOTES:
             *     - Unbinds touch open (if currently bound)
             *     - Opens menu
             *     - Binds touch close
             */
            function bindTouchOpen () {

                $mmOpenBtn.on({
                    'click.mmTouchOpen': function (e) {
                        e.preventDefault();
                        unbindTouchOpen();
                        openMm(e);
                        bindTouchClose();
                    }
                });

                // Stop touched subcats from collapsing mm
                $mm.on('click.mmTouchOpen', function (e) {
                    e.stopPropagation();
                });

            }

            /**
             * Binds (CLOSE) touch handler for tablet (modified desktop) view
             *
             * NOTES:
             *     - Unbinds touch open (if currently bound)
             *     - Opens menu
             *     - Binds touch close
             */
            function bindTouchClose () {
                $mmWrap.on({
                    'click.mmTouchClose': function (e) {
                        e.preventDefault();
                        unbindTouchClose();
                        closeMm(e);
                        bindTouchOpen();
                    }
                });
            }

            /**
             * Unbinds (OPEN) touch handler
             */
            function unbindTouchOpen () {
                $mmWrap.off('click.mmTouchOpen');
            }

            /**
             * Unbinds (CLOSE) touch handler
             */
            function unbindTouchClose () {
                $mmWrap.off('click.mmTouchClose');
            }

            function bindEnterOpen () {
                $mmOpenBtn.on({
                    'keydown.mmEnterOpen': function (e) {
                        if (e.which === 13) {
                            e.preventDefault();
                            unbindKeypress();
                            openMm(e);
                            bindEnterClose();
                        }
                    }
                });

                // Stop subcats from collapsing mm
                $mm.on('keydown.mmEnterOpen', function (e) {
                    if (e.which === 13) {
                        e.stopPropagation();
                    }
                });
            }

            function bindEnterClose () {
                $mmOpenBtn.on({
                    'keydown.mmEnterClose': function (e) {
                        if (e.which === 13) {
                            e.preventDefault();
                            unbindKeypress();
                            closeMm(e);
                            bindEnterOpen();
                        }
                    }
                });
            }

            function unbindKeypress () {
                $mmOpenBtn.off('keydown.mmEnterOpen');
                $mmOpenBtn.off('keydown.mmEnterClose');
            }

            /**
             * DOM manipulation to open the mm
             * @param  {Event} e - click / touch evt
             *
             * NOTES:
             *     - Rotates arrow
             *     - Adds mmOpen class to the mm
             */
            function openMm (e) {
                // Animate svg arrow
                TweenLite.to($mmSvgArrow, 0.2, { rotation: -180 });

                // Set aria attr value to button
                $mmOpenBtn.attr('aria-expanded', true);

                // Add mmOpen class (if needed)
                if (!$mm.hasClass(mmOpenClass)) {
                    $mm.toggleClass(mmOpenClass);
                }

            }

            /**
             * DOM manipulation to close the mm
             * @param  {Event} e - click / touch evt
             *
             * NOTES:
             *     - Reverse rotates arrow
             *     - Adds mmOpen class to the mm
             *     - Removes rowActive and panelOpen classes on exiting the menu
             */
            function closeMm (e) {

                // Animate svg arrow
                TweenLite.to($mmSvgArrow, 0.2, { rotation: 0 });

                // Set aria attr value to button
                $mmOpenBtn.attr('aria-expanded', false);

                // Remove mmOpen class (if needed)
                if ($mm.hasClass(mmOpenClass)) {
                    $mm.removeClass(mmOpenClass);
                }

            }


            /*----------[Panel Functionality]----------*/


            // If on desktop, use menu aim
            if (isDesktop) {
                bindPanelAim();
                bindPanelEnterOpen();
            } else {
                bindPanelTouch();
            }

            /**
             * Binds an evt handler to collapse (ONLY) the mm panel on exiting the menu
             *
             * NOTES:
             *     - This is to prevent the panel from
             *       staying open all the time on an auto-expanded mm
             *     - Is NOT used when the mm is openable / closeable
             */
            function bindPanelClose () {
                $mmWrap.on({
                    'mouseleave.mmExpandedPanel': closePanel
                });
            }

            /**
             * Use menu aim plugin on mm for the pure desktop view (NOT tablet)
             *
             * NOTES:
             *     - Binds 'activate' handler to fire the panel open functionality
             *     - Binds 'exitMenu' handler that simply deactives the active row
             */
            function bindPanelAim () {
                if ($mmCat.menuAim){
                    $mmCat.menuAim({
                        rowSelector: rowSelector,
                        activate: function (currentRow) {
                            openPanel(null, currentRow);
                        },
                        deactivate: function (row) {
                            // Not needed right now
                        },
                        exitMenu: function () {
                            return true; // REQUIRED -> deactivates last active row on menu exit
                        }
                    });
                }
            }

            /**
             * Use touch handling for tablet (modified desktop) view
             *
             * NOTES:
             *     - Use click event directly with open panel
             */
            function bindPanelTouch () {
                $mmRows.on({
                    'click.panelTouchOpen': openPanel
                });
            }

            function bindPanelEnterOpen () {
                $mmRows.on({
                    'keydown.panelEnterOpen': function (e) {
                        if (e.which === 13 && !$(e.target).hasClass('default')) {
                            e.preventDefault();
                            unbindPanelEnterEvents();
                            openPanel(e, $(this));
                            bindEnterPanelClose();
                        }
                    }
                });

                // Stop subcats from collapsing panel
                $mmPanel.on('keydown.panelEnterOpen', function (e) {
                    if (e.which === 13) {
                        e.stopPropagation();
                    }
                });
            }

            function bindEnterPanelClose () {
                $mmRows.on({
                    'keydown.panelEnterClose': function (e) {
                        if (e.which === 13) {
                            e.preventDefault();
                            unbindPanelEnterEvents();
                            closePanel(e);
                            bindPanelEnterOpen();
                        }
                    }
                });
            }

            function unbindPanelEnterEvents () {
                $mmRows.off('keydown.panelEnterOpen');
                $mmRows.off('keydown.panelEnterClose');
            }

            /**
             * Open panel functionality
             * @param  {Event} e - triggering event
             * @param  {String} currentRow - currently selected mm category
             *
             * NOTES:
             *     - Start by deactivating any previously activated sub-category links
             *     - Cache and activate the currently clicked sub-cat
             *     - Get the id for matching panel contents and show (only) them
             */
            function openPanel (e, currentRow) {

                if (flx.util.isEmpty(currentRow)) {
                    e.preventDefault();
                }

                var $currentRow = (flx.util.isEmpty(currentRow))
                    ? $(this)
                    : $mm.find(currentRow);
                var subcatTarget = $currentRow.attr('subcat-target');
                var $subcatWrap = $('.subcatPanel');
                var $allSubcats = $('.subcatCols');
                var $subcatTarget = $subcatWrap.find('[content-id="' + subcatTarget + '"]');

                $mmRows.removeClass(rowActiveClass); // Remove class from all rows
                $currentRow.addClass(rowActiveClass);
                $mm.addClass(panelOpenClass);
                $allSubcats.removeClass('active');
                $subcatTarget.addClass('active');

                flx.overlays.clearAndCreateByType('megaMenu');

            }

            /**
             * Close panel functionality
             * @param  {Event} e - triggering event
             *
             * NOTES:
             *     - Remove active class from mm category btn
             *     - Remove class for open mm panel
             */
            function closePanel (e) {

                // Remove panelOpen class when user exits the menu (if needed)
                if ($mm.hasClass(panelOpenClass)) {
                    $mm.removeClass(panelOpenClass);
                }

                // Remove active class from all rows
                $mmRows.removeClass(rowActiveClass);
                flx.overlays.removeAllDomElems();

            }
        });
    });
})();
