(function () {

    var config = {
        inputClass: '.js-spinnerHtmlContent',
        spinnerGlobal: true,
        noGlobalClass: 'noGlobal',
        spinnerCache: {}
    };

    flx.spinner = {

        /**
         * Initializes the spinnner module configuration and returns the configuration
         *
         * @returns {Object} config - module configuration
         *
         * NOTES:
         *    - Fetches the spinner HTML content from a hidden input and caches it as
         *      config.spinnerHtml
         *    - Fetches and preloads the images contained in the $spinnerElem
         */
        init: function init () {

            var $spinnerInput = $(config.inputClass);
            var spinnerHtml = config.spinnerHtml = unescape($spinnerInput.val().trim());
            var $spinnerElem = $(spinnerHtml);
            var spinnerClass = config.spinnerClass = $spinnerElem.attr('class');
            var spinnerImgSrcs = flx.spinner.getSpinnerImgSrcs($spinnerElem);
            var preloadedSpinnerImgs = flx.util.preloadImages(spinnerImgSrcs);

            return config;

        },

        /**
         * Loops over every image inside of the passed in spinner element and stores their src
         * paths in an array
         *
         * @param {jQ Object} $spinnerElem - the spinner element
         * @returns {Array} spinnerImgSrcs - array of spinner img srcs (for preloading)
         */
        getSpinnerImgSrcs: function getSpinnerImgSrcs ($spinnerElem) {

            var spinnerImgSrcs = [];
            var $spinnerImgs = $spinnerElem.find('img');

            $spinnerImgs.each(function (index, imgElem) {

                var $imgElem = $(imgElem);

                if (flx.util.isNotEmpty($imgElem.attr('src'))) {
                    spinnerImgSrcs.push($imgElem.attr('src'));
                }
            });

            return spinnerImgSrcs;

        },

        /**
         * Passes array of img srcs to the image preloader utility, to avoid any issues with
         * placing the spinner on the page in the correct position
         *
         * @param {jQ Object} $spinnerElem - cached spinner element
        * @return {Array} preloadedSpinnerImgs - array of unattached DOM elems corresponding to our
        *   preloaded images
         */
        preloadImages: function preloadImages ($spinnerElem) {

            var spinnerImgSrcs
                = config.spinnerImgSrcs
                = flx.spinner.getSpinnerImgSrcs($spinnerElem);

            var preloadedSpinnerImgs = flx.util.preloadImages(spinnerImgSrcs);

            return preloadedSpinnerImgs;

        },

        /**
         * Simple configuration getter
         *
         * @param {Boolean} logOutput - logs config obj as console output when set to 'true'
         * @returns {Object} config - spinner module configuration object
         */
        getConfig: function getConfig (logOutput) {

            if (logOutput) {
                console.log(config);
            }

            return config;

        },

        /**
         * Takes an options object and creates a unique spinner from the pre-defined spinner HTML
         *
         * @param {Object} options - options obj with optional class and a required spinnerId
         * @returns {jQ Object} $spinnerElem - DOM element converted to jQ object
         *
         * NOTES:
         *    - Consumers may pass in an optional class which gets appended to the element on
         *      creation
         */
        generate: function generate (options) {

            var spinnerClass;
            var $spinnerElem;
            var spinnerGlobal = flx.spinner.isGlobalSpinner(options);
            var noGlobalClass = config.noGlobalClass;

            // Make sure we have an options and the required spinnerId is passed in
            if (flx.util.isEmpty(options) || flx.util.isEmpty(options.spinnerId)) {
                console.log('Spinner generate warning: Must provide an options object with '
                    + 'a valid spinnerId');
                return null;
            }

            // If consumer passes in optional class, make sure to add it to the element
            if (flx.util.isNotEmpty(options.class) && typeof options.class === 'string') {
                spinnerClass = config.spinnerClass + ' ' + options.class;
            } else {
                spinnerClass = config.spinnerClass;
            }

            // Create and cache the element, then add class and data attr for spinner id
            $spinnerElem = $(config.spinnerHtml);
            $spinnerElem.addClass(spinnerClass);
            $spinnerElem.attr('data-spinner-id', options.spinnerId);

            // If a non-global spinner is requested, tag it with appropriate class
            if (!spinnerGlobal) {
                $spinnerElem.addClass(noGlobalClass);
            }

            return $spinnerElem;

        },

        /**
         * Generates a new spinner elem with a UUID, hides all currently active ones (so we never
         * have more than one at a time), then appends it to the DOM, sets position, animates
         * and adds it to the spinner cache
         *
         * @param {Object} options - options object for module consumers
         * @returns {String} spinnerId - spinnerId which can be used to clear the cache and hide it
         */
        show: function show (options) {

            // Initialize options object
            var defaults = {};
            options = $.extend(true, {}, defaults, options);

            // Check if we're working with a global spinner or not
            var spinnerGlobal = flx.spinner.isGlobalSpinner(options);

            // Create collection of all existing spinners
            var $existingSpinners = $(flx.spinner.getAll());

            // Generate a unique identifier for the spinner and apply it to the opts object
            var spinnerId = options.spinnerId = flx.util.generateQuickUuid();

            // Create new spinner
            var $spinnerElem = flx.spinner.generate(options);

            // Find any existing global spinners and hide them
            if ($existingSpinners.length > 0) {
                var $existingGlobalSpinners = $existingSpinners.filter(function () {
                    return $(this).hasClass(config.noGlobalClass) == false;
                });
                var existingGlobalSpinnersCount = $existingGlobalSpinners.length;

                // Hide all global spinners except the last one
                $existingGlobalSpinners.each(function (i) {
                    if (i < (existingGlobalSpinnersCount - 1)) {
                        flx.spinner.hideById($(this).attr('data-spinner-id'));
                    }
                });
            }

            // Add our new spinner to page
            if (spinnerGlobal) {
                $('body').append($spinnerElem);
                flx.overlays.clearAndCreateByType('spinner');
            } else {
                $(options.target).append($spinnerElem);
            }

            // Add spinner to the spinner cache
            config.spinnerCache[spinnerId] = $spinnerElem;

            flx.spinner.animate($spinnerElem);

            return spinnerId;

        },

        /**
         *  Animates the spinner element using GreenSock to rotate the img
         *
         * @param {jQ Object} $spinnerElem - the spinner DOM elem
         */
        animate: function animate ($spinnerElem) {

            // Check the spinner element is passed in and is valid, otherwise early return and warn
            if (flx.util.isEmpty($spinnerElem)
                || !flx.util.isJQueryElem($spinnerElem)
                || $spinnerElem.length <= 0) {

                console.log('Spinner setPosition warning: Must pass in a valid, non-empty '
                    + 'jQuery object');

                return null;

            }

            var $spinnerImgElem = $spinnerElem.find('.fsSpinnerImg');

            // Do the rotation animation
            TweenMax
                .to($spinnerImgElem, 1, {
                    rotation: 360,
                    ease: Quad.easeInOut,
                    repeat: -1
                });

        },

        /**
         * Simple DOM element getter for spinner elems
         *
         * @returns {jQ Object} $spinnerElem - spinner elem(s) fetched by configurable class name
         */
        getAll: function getAll () {

            var $spinnerElem = $('.' + config.spinnerClass);

            return $spinnerElem;

        },

        /**
         * Hides all spinner elems by getting them via flx.spinner.getAll, removing them from the
         * DOM, then emptying out the spinner cache
         */
        hideAll: function hideAll () {

            // Get and remove all spinners from the DOM
            var $allSpinnerElems = flx.spinner.getAll();
            flx.overlays.removeAllDomElems();
            $allSpinnerElems.remove();

            // Re-init spinnerCache to empty object
            config.spinnerCache = {};

        },

        /**
         * Gets a spinner by its spinnerId
         *
         * @param {String} spinnerId - UUID for targeted spinner
         * @returns {jQ Object} $spinnerElem - the spinner DOM elem
         *
         * NOTES:
         *    - Checks the spinnerCache directly and if there is a match, returns that value
         *      otherwise it early returns and logs a warning
         */
        getById: function getById (spinnerId) {

            // Make sure we a spinnerId
            if (flx.util.isEmpty(spinnerId)) {
                console.log('Spinner getById Warning: Must provide a valid spinnerId');
                return null;
            }

            // Make sure the spinnerCache has been initialized
            if (flx.util.isEmpty(config.spinnerCache)) {
                console.log('Spinner getById Warning: There are no currently active spinners');
                return null;
            }

            // Make sure the value exists in the spinnerCache
            if (flx.util.isEmpty(config.spinnerCache[spinnerId])) {
                console.log('Spinner getById Warning: Selected spinner is no longer active');
                return null;
            }

            var $spinnerElem = config.spinnerCache[spinnerId];

            return $spinnerElem;

        },

        /**
         * Hides a spinner by its spinnerId
         *
         * @param {String} spinnerId - UUID for targeted spinner
         * @returns {jQ Object} $spinnerElem - the spinner DOM elem
         *
         * NOTES:
         *    - Gets the spinner by its id, then removes it from the DOM and deletes it from cache
         */
        hideById: function hideById (spinnerId) {

            var $spinnerElem = flx.spinner.getById(spinnerId);

            // If an elem was fetched, remove it from the DOM
            if (flx.util.isNotEmpty($spinnerElem)) {
                flx.overlays.removeAllDomElems();
                $spinnerElem.remove();
            }

            // Delete spinnerCache entry for given id
            delete config.spinnerCache[spinnerId];

        },

        /**
         * Check if "spinnerGlobal" is set to "false" and make sure we return a boolean value. If
         * anything other than "false" is passed, it'll return "true".
         *
         * @param {Object} options - options object for module consumers
         * @returns {Bolean} true or false
         */
        isGlobalSpinner: function isGlobalSpinner (options) {
            return options.spinnerGlobal === false
                || options.spinnerGlobal === 'false'
                ? false
                : config.spinnerGlobal;
        }

    };

    // Initialize the module
    flx.spinner.init();

})();
