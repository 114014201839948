(function iifeFlexBase () {

    window.flx = {
        state: {}
    };

    /*----------[Module Configuration]----------*/

    var config = {
        env: 'no-env',
        rfkEnabled: false,
        targets: {
            body: 'body',
            miniCartDisabled: '.js-miniCartDisabled',
            absoluteOrRelativeDomain: '.js-absoluteOrRelativeDomain',
            envInput: '.js-runningEnv',
            deviceType: '.js-deviceTypeDetection',
            pageTypeInput: '.js-pageType',
            dataDump: '.js-dataDump',
            kongCustomerId: '.js-kongCustomerId',
            userData: '.js-jsonUserData',
            microSite: {
                cookieName: '.js-microSiteCookieName'
            },
            redirectedQueryParam: '.js-redirectedQueryParam',
            rfkEnabled: '.js-rfkEnabled',
            siteType: '.js-siteType',
            segmentKey: '.js-segmentKey',
            liveHoxChatID: '.js-liveHoxChatID',
            customizedPricingThroughJS: '.js-customizedPricingThroughJS',
            collectDebitCardInfo: '.js-collectDebitCardInfo',
            fulfillmentProvider: '.js-fulfillmentProvider',
            disableACHCollection: '.js-disableACHCollection',
            waterfall: '.js-waterfall',
            globalBanner: '.js-globalBanner',
        },
        classNames: {
            isWaterfallUser: 'js-isWaterfallUser',
            waterfallEnabled: 'js-waterfallEnabled',
            waterfallLender: 'js-waterfallLender-',
        }
    };

    var Util = flx.util = {
        /**
         * Events thrown
         */
        events: {
            REMOTE_FLAGS_LOADED: 'REMOTE_FLAGS_LOADED',
            LOAD_REMOTE_FLAGS: 'LOAD_REMOTE_FLAGS',
            FPC_LOAD_ANALYTICS: 'FPC_LOAD_ANALYTICS',
            FPC_LOADED: 'FPC_LOADED'
        },

        /**
        * Simple configuration getter
        *
        * @param {Boolean} logOutput - logs config obj as console output when set to 'true'
        * @returns {Object} config - spinner module configuration object
        */
        getConfig: function getConfig (logOutput) {

            if (logOutput) {
                console.log(config);
            }

            return config;

        },

        /*---------------[Storage Methods]---------------*/

        /**
         * Checks if the current browser has the designated storage capability
         * @param  {String} storageType - 'localStorage' || 'sessionStorage'
         * @return {Boolean} hasStorageCapability - true if browser can use designated storage api
         */
        hasStorageCapability: function hasStorageCapability (storageType) {

            // Init vars
            var hasStorageCapability = false;
            var storageMechanism = window[storageType];

            // If the native object doesn't exist, early return as false
            if (typeof storageMechanism !== 'object') {
                return false;
            }

            // If we can successfully set and delete a stored item w/o errs, the browser is
            // capable and we set the return val to true
            try {
                storageMechanism.setItem('flxStorageTest', 1);
                storageMechanism.removeItem('flxStorageTest');
                hasStorageCapability = true;
            } catch (err) {
                hasStorageCapability = false;
            }

            return hasStorageCapability;

        },

        /**
         * Fetches a value from either local or session strage
         * @param  {String} storageType - 'localStorage' || 'sessionStorage'
         * @param  {String} storageKey - key of value to fetch
         * @param  {*} defaultReturn - return value (returned if the fetched value was empty)
         * @return {String} storedValue - value retrieved from local or session storage
         */
        fetchFromStorage: function fetchFromStorage (storageType, storageKey, defaultReturn) {

            // If storageMechanism is unavailable, early return with the defaultReturn
            if (!flx.util.hasStorageCapability(storageType)) {
                return defaultReturn;
            }

            // Fetch and return the current value from storage, if empty use defaultReturn
            return window[storageType].getItem(storageKey) || defaultReturn;

        },

        /**
         * Caches a value in either local or session strage
         * @param  {String} storageType - 'localStorage' || 'sessionStorage'
         * @param  {String} storageKey - key of value to cache
         * @param  {String} valueToStore - value to cache in the local or session storage
         * @return {String} valueToStore - valueToStore unchanged
         */
        cacheInStorage: function cacheInStorage (storageType, storageKey, valueToStore) {

            // If storageMechanism is unavailable, early return with the defaultReturn
            if (!flx.util.hasStorageCapability(storageType)) {
                return;
            }

            // Fetch and return the current value from storage, if empty use defaultReturn
            window[storageType].setItem(storageKey, valueToStore);

            return valueToStore;

        },

        /*---------------[Time and Date Methods]---------------*/

        /**
         * Creates and returns an ISO string for the current moment in time
         */
        stampNow: function stampNow () {
            return new Date().toISOString();
        },

        /*---------------[DOM Methods]---------------*/

        /**
         * If the passed-in item is a non-empty jQuery object, the fn returns true
         *
         * @param {jQ} $elemToCheck - item to check if it is a non-empty jQ object
         * @returns {Boolean} isNonEmptyJq - is passed-in item a non-empty JQ object
         */
        isNonEmptyJq: function isNonEmptyJq ($elemToCheck) {

            // Initialize to false
            var isNonEmptyJq = false;

            // If it is a jQuery element with a length greater than one, we know it's a non-empty
            // jQ elem so we set it to true
            if (flx.util.isJQueryElem($elemToCheck) && $elemToCheck.length > 0) {
                isNonEmptyJq = true;
            }

            return isNonEmptyJq;

        },

        /**
         * Checks whether passed in value is a jQ object instance
         * @param {Any} $elem - value to check if is a jQ object
         * @return {Boolean} isJQueryElem - boolean indicating if is a valid jQ object
         */
        isJQueryElem: function isJQueryElem ($elem) {

            // Normalize empty vals to an empty object
            $elem = $elem || {};

            // Check if jQ object
            if ($elem instanceof $) {
                return true;
            } else {
                return false;
            }

        },

        /**
         * Preloads a set of images from an array of img paths
         * @param {Array} imgSrcs - array of uri strings for images we want to preload
         * @return {Array} preloadedImages - array of unattached DOM elems corresponding to our
         *   preloaded images
         */
        preloadImages: function preloadImages (imgSrcs) {

            if (flx.util.isEmpty(imgSrcs)) {
                console.log('Preload images warning: Must pass in a non-empty array of img srcs');
            }

            var preloadedImages = [];

            for (var i = 0; i < imgSrcs.length; i++) {
                preloadedImages[i] = new Image();
                preloadedImages[i].src = imgSrcs[i];
            }

            return preloadedImages;

        },

        /**
         * Scrolls the page to the top, reused by a few different features
         */
        scrollToTop: function scrollToTop (animSpeed) {

            var scrollSpeed = flx.util.isEmpty(animSpeed) ? 500 : animSpeed;

            $('body,html').stop(true, true).animate({scrollTop: 0}, scrollSpeed);

        },

        /**
         * Scrolls the page to the target elem
         */
        scrollToElem: function scrollToElem ($targetElem) {

            var offset;

            if (flx.util.isJQueryElem($targetElem) && $targetElem.length > 0) {

                offset = Math.max(0, $targetElem.offset().top - 10);
                $('body,html').stop(true, true).animate({scrollTop: offset}, 500);

            }

        },

        /**
         * Extracts a value from the hidden input elem
         *
         * @param {String} htmlClass - selector str for hidden input we want to extract data from
         * @returns {String} strFromInput - str extracted from the targeted hidden input
         */
        extractFromHiddenInput: function extractFromHiddenInput (htmlClass) {

            // Init vars
            var strFromInput = '';
            var $inputElem = $(htmlClass);
            var isNonEmptyJq = flx.util.isNonEmptyJq($inputElem);

            // Cache from the input contents
            if (isNonEmptyJq) {
                strFromInput = $inputElem.val();
            }

            return strFromInput;

        },

        /**
         * Extracts a value from the hidden JSON script tag and parses it when possible
         *
         * @param {String} htmlClass - selector str for JSON script we want to extract data from
         * @returns {Object} objectFromScript - parsed JSON || empty object
         */
        extractJsonFromScript: function extractJsonFromScript (htmlClass) {

            // Init vars
            var objectFromScript = {};
            var strFromScript = '';
            var $inputElem = $(htmlClass);

            // Cache from the script contents
            if (flx.util.isNonEmptyJq($inputElem)) {
                strFromScript = $inputElem.text();
            }

            // Do the JSON parse step
            if (flx.util.isNotEmpty(strFromScript)) {
                objectFromScript = flx.util.safeParse(strFromScript) || {};
            }

            return objectFromScript;

        },

        /*---------------[Data Extraction and Data Setter Methods]---------------*/

        getIsMiniCartDisabled: function getIsMiniCartDisabled () {
            return flx.util.extractFromHiddenInput(config.targets.miniCartDisabled);
        },

        getAbsoluteOrRelativeDomain: function getAbsoluteOrRelativeDomain () {

            return flx.util.extractFromHiddenInput(config.targets.absoluteOrRelativeDomain);

        },

        getLiveHoxChatID: function getLiveHoxChatID () {

            return flx.util.extractFromHiddenInput(config.targets.liveHoxChatID);

        },

        getCustomizedPricingThroughJS: function getCustomizedPricingThroughJS () {

            return flx.util.extractFromHiddenInput(config.targets.customizedPricingThroughJS);

        },

        getCollectDebitCardInfo: function getCollectDebitCardInfo () {

            return flx.util.extractFromHiddenInput(config.targets.collectDebitCardInfo);

        },

        getFulfillmentProvider: function getFulfillmentProvider () {

            return flx.util.extractFromHiddenInput(config.targets.fulfillmentProvider);

        },

        getDisableACHCollectionFlag: () => {
            return flx.util.extractFromHiddenInput(config.targets.disableACHCollection);
        },

        getWaterfall: function getWaterfall () {

            return flx.util.extractFromHiddenInput(config.targets.waterfall);

        },

        /**
         * Extracts deviceType value from input field in the DOM
         * @return {String} extractedDeviceType - plain string denoting user's deviceType
         */
        extractDeviceType: function extractDeviceType () {

            var extractedDeviceType = flx.util.extractFromHiddenInput(config.targets.deviceType);
            return extractedDeviceType;

        },

        /**
         * Gets deviceType from state object
         * @return {String} deviceType - plain string denoting what deviceType the user is on
         */
        getDeviceType: function getDeviceType () {

            var deviceType = flx.state.deviceType;
            return deviceType;

        },

        /**
         * Get name of system from hidden input
         * @return {String} siteType - name of the system
         *
         * NOTES:
         *      - This value is passed through from an env var and can be modified w/o a code change
         */
        getSiteType: function getSiteType () {

            var siteType = flx.util.extractFromHiddenInput(config.targets.siteType);
            return siteType;

        },

        /**
         * Get name of system from hidden input
         * @return {String} siteType - name of the system
         *
         * NOTES:
         *      - This value is passed through from an env var and can be modified w/o a code change
         */
        getSegmentKey: function getSegmentKey () {

            var segment = flx.util.extractFromHiddenInput(config.targets.segmentKey);

            return segment;

        },

        /**
         * Get the domain of the current page
         * @return {String} fullDomain - hostname of the current page (ex. flexshopper.com)
         */
        getFullDomain: function getFullDomain () {

            var fullDomain = window.location.hostname;
            return fullDomain;

        },

        /**
         * Gets the user's Kong Customer ID from the DOM if logged-in
         *
         * @returns {String} kongCustomerId - user's kongCustomerId
         */
        getKongCustomerId: function getKongCustomerId () {

            var kongCustomerId = '';

            // Get from hidden elem
            kongCustomerId = flx.util.extractFromHiddenInput(config.targets.kongCustomerId);

            return kongCustomerId;

        },

        /**
         * Gets the user's parsed data from the DOM if logged-in and it is available
         *
         * @returns {Object} kongCustomerId - user's kongCustomerId
         */
        getUserData: function getUserData () {

            var userData = {};

            // Get from hidden script elem
            userData = flx.util.extractJsonFromScript(config.targets.userData);

            return userData;

        },

        setUserData: function (data) {
            if (data && data.address) {
                data.address.state = data.address.region;
                data.address.zipCode = data.address.postalCode;
            }

            var userData = flx.util.extractJsonFromScript(config.targets.userData) || {};
            if (typeof data === 'object') {
                for (key in data) {
                    userData[key] = data[key];
                }
            }
            var dataStr = JSON.stringify(userData);
            $(config.targets.userData).text(dataStr);
        },

        /**
         * Sets deviceType on state object (if not already set)
         * @return {String} deviceType - plain string denoting what deviceType the user is on
         */
        setDeviceType: function setDeviceType () {

            var extractedDeviceType = flx.util.extractDeviceType();
            var deviceType
                = flx.state.deviceType
                = flx.util.setIfEmpty(flx.state.deviceType, extractedDeviceType);

            return deviceType;

        },

        /**
         * Extracts and caches the currently running env name
         *
         * @param {Boolean} shouldLog - indicates whether we want to log the env after setting it
         *
         * NOTES:
         *     - The env var cannot be accessed directly
         */
        setEnv: function setEnv (shouldLog) {

            config.env = flx.util.extractFromHiddenInput(config.targets.envInput);

            if (shouldLog) {
                console.log('You are running in: ' + config.env + ' environment');
            }

        },

        setRfkStatus: function setRfkStatus (shouldLog) {
            config.rfkEnabled = flx.util.extractFromHiddenInput(config.targets.rfkEnabled);

            if (shouldLog) {
                console.log('Reflektion is currently set to: ' + config.rfkEnabled);
            }
        },

        /**
         * Gets and returns the currently running env name
         *
         * @param {Boolean} shouldLog - indicates whether we want to log to the console as well
         * @return {String} env - returns the env name as a string
         *
         * NOTES:
         *     - The env var cannot be accessed directly and only interacted with through this
         *       util and the setter util
         */
        getEnv: function getEnv (shouldLog) {

            if (shouldLog) {
                console.log('You are running in: ' + config.env + ' environment');
            }

            return config.env;

        },

        getRfkStatus: function getRfkStatus (shouldLog) {

            if (shouldLog) {
                console.log('Reflektion is currently set to: ' + config.rfkEnabled);
            }

            return config.rfkEnabled;

        },

        /**
         * Print Session ID to the console
         *
         * NOTES:
         *     - If running in production env, will not work
         */
        getSessionId: function getSessionId () {

            if (flx.util.getEnv() !== 'production') {
                console.log($('.js-sessionIdInput').val());
            }

        },

        /**
         * Grabs page type from hidden input and returns it
         * @returns {String} pageType - type of page we are currently on (i.e. 'search')
         */
        extractPageType: function extractPageType () {
            return flx.util.extractFromHiddenInput(config.targets.pageTypeInput);
        },

        /*---------------[Misc Methods]---------------*/

        /**
         * Generate a quick and dirty UUID-like identifier
         *
         * NOTES:
         *      - This a non-compliant UUID builder and should NOT be used in lieu
         *      of a true UUID
         */
        generateQuickUuid: function generateQuickUuid () {

            var uuidStr1 = Math.random().toString(36).substring(2, 15);
            var uuidStr2 = Math.random().toString(36).substring(2, 15);

            return uuidStr1 + uuidStr2;

        },

        /**
         * Slow down the execution of event handlers bound to quick firing events
         * @param  {fn} fn - event handler to be debounced
         * @param  {integer} wait - number of ms to wait since last invocation to fire
         * @param  {boolean} immediate - 'true' triggers on leading (not trailing) edge of interval
         * @return {function} result - debounced function
         */
        debounce: function debounce (fn, wait, immediate) {
            var timeout;
            var args;
            var context;
            var timestamp;
            var result;

            function later () {
                var last = Date.now() - timestamp;
                if (last < wait && last >= 0) {
                    timeout = setTimeout(later, wait - last);
                } else {
                    timeout = null;
                    if (!immediate) {
                        result = fn.apply(context, args);
                        if (!timeout) {
                            context = args = null;
                        }
                    }
                }
            };

            return function curriedDebounce () {
                context = this;
                args = arguments;
                timestamp = Date.now();
                var callNow = immediate && !timeout;
                if (!timeout) {
                    timeout = setTimeout(later, wait);
                }
                if (callNow) {
                    result = fn.apply(context, args);
                    context = args = null;
                }

                return result;
            };
        },

        /**
         * Zip Code Validation
         * @param  {String}  val - zip code value
         * @return {Boolean | String} val - true if zip code is valid - error msg if invalid
         */
        isValidZip: function isValidZip (val) {

            if (!val) {
                return 'Please enter a zip code.';
            }

            if (val.match(/^\d+$/)) {
                if (val.length === 5) {
                    return true;
                } else {
                    return 'Zip code must be 5 characters.';
                }
            } else {
                return 'Please enter numbers only.';
            }

        },

        /**
         * On iDevices, there is a concept of a back-forward cache that stores the exact page state
         * at the time of redirection. This is problematic when there's a spinner on the page. This
         * method attempts to solve the b-f cache issue by hiding the spinner on pageshow
         */
        killBfCache: function killBfCache () {

            // Bind to pageshow event and reload when page is bf cached
            $(window).on('pageshow', function bfCachePageShowHandler (e) {

                // Check persisted property which indicates it was bf cached
                if (e.originalEvent.persisted) {

                    // Reload the page if needed
                    setTimeout(function bfCacheSpinnerHide () {
                        flx.spinner.hideAll();
                    }, 10);

                }

            });

        },

        /**
         * Dumps any data which is passed to the front-end
         *
         * @param {Boolean} disableLog - if true, the fn returns the value but does not log it
         * @returns {Object} dataDumpObj - object with dumped data extracted from DOM
         */
        dumpData: function dumpData (disableLog) {

            // Init vars
            var dataDumpObj = {};
            var dataDumpJson = '';
            var $dataSource = $(config.targets.dataDump);

            // If the element is in the DOM, get the data and dump it
            if (flx.util.isJQueryElem($dataSource) && $dataSource.length > 0) {

                dataDumpJson = $dataSource.text().trim();
                dataDumpObj = flx.util.safeParse(dataDumpJson) || {};

                // Always log unless actively disabled
                if (disableLog !== true) {

                    console.log('Dump Data (JSON string):');
                    console.log(dataDumpJson);

                    console.log('Dump Data (Object):');
                    console.log(dataDumpObj);

                }

            }

            return dataDumpObj;

        },

        showGlobalBanner: function showGlobalBanner () {
            var $banner = $(config.targets.globalBanner);
            if ($banner.length) {
                $banner.show();
            }
        },

        /*---------------[Empty/Null-Checker Methods]---------------*/

        /**
         * Checks if value is an empty plain js object
         * @param  {Any} val - value to check
         * @return {Boolean} isEmptyPlainObj - true if val is an empty plain js obj
         */
        isEmptyPlainObj: function isEmptyPlainObj (val) {

            // Not an object
            if (typeof val !== 'object') {
                return false;
            }

            // Object.keys prop doesn't exist
            if (!Object.keys(val)) {
                return false;
            }

            // Object has keys (and therefore is NOT empty)
            if (!Object.keys(val).length === 0) {
                return false;
            }

            // Avoid crashing out due to stringifying circular object structures
            try {

                // Stringified object doesn't match stringified empty plain obj
                if (JSON.stringify(val) !== JSON.stringify({})) {
                    return false;
                }

            } catch (err) {

                // If it has a cicular structure, it is obviously NOT an empty plain object
                return false;

            }

            // Passed all checks, IS an empty obj
            return true;

        },

        /**
         * Checks if value is empty (undefined, null or an empty string/array/obj)
         * @param  {Any} val - value to check
         * @return {Boolean} isEmpty - true if val is empty
         */
        isEmpty: function isEmpty (val) {

            // Not needed if empty checking a boolean val directly, log warning
            if (typeof val === 'boolean') {
                console.log('Warning: you are testing a boolean value for \'emptiness\'. ' +
                    'Consider evaluating it directly.');
            }

            // If undefined, null, empty string, empty {} or empty [], then val is empty
            if (typeof val === 'undefined' || val === null || val === ''
                || flx.util.isEmptyPlainObj(val)
                || (Array.isArray(val) && val.length <= 0)) {
                return true;
            }

            // Otherwise val is not empty
            return false;

        },

        /**
         * Checks if value is NOT empty (undefined, null or an empty string)
         * @param  {Any} val - value to check
         * @return {Boolean} isNotEmpty - true if val is NOT empty
         */
        isNotEmpty: function isNotEmpty (val) {
            return !(this.isEmpty(val));
        },

        /*---------------[Object / Array / Var Methods]---------------*/

        /**
         * Takes an object and cleans out an empty properties, without modifying the original
         *
         * @param {Object} obj - object to remove empty props from
         * @returns {Object} cleanedObj - copied object with empty props removed
         */
        cleanEmptyProps: function cleanEmptyProps (obj) {

            // If we're trying to clean a non-object entity, warn and early return
            if (typeof obj !== 'object') {

                console.log('cleanEmptyProps warning: you are trying to clean properties '
                    + 'from a [' + typeof obj + '] which is not an object. Returning '
                    + 'the value unmodified');

                return obj;

            }

            // Create a JSON copy so we don't modify the original
            var cleanedObj = flx.util.copyByJson(obj);

            // Loop over the object and delete empty properties
            for (var propName in cleanedObj) {
                if (flx.util.isEmpty(cleanedObj[propName])) {
                    delete cleanedObj[propName];
                }
            }

            return cleanedObj;

        },

        /**
         * Convert arg to string if possible, otherwise use arg as provided
         * @param  {*} arg - value to stringify
         * @return {*}
         */
        safeStringify: function safeStringify (arg) {
            if (arg && arg.toString) {
                return arg.toString();
            }
            if (arg) {
                return arg;
            }
            return null;
        },

        /**
         * Takes an array and returns a random item from it
         * @param  {Array} val - array to grab random item from
         * @return {Any} randArrayItem - randomly selected item from array
         */
        getRandArrayElem: function getRandArrayElem (val) {

            // If it's not an array, err out
            if (!Array.isArray(val)) {
                return console.log('util.getRandArrayElem warning: the value you passed in is not '
                    + 'an array');
            }

            // If the array is empty, immediately return it and log a warning
            if (flx.util.isEmpty(val)) {
                return console.log('util.getRandArrayElem warning: the value you passed in '
                    + 'is empty');
            }

            var randIndex = Math.floor(Math.random() * val.length);
            return val[randIndex];

        },

        /**
         * Returns a true copy of an obj by stringifying then reparsing it
         * @param  {Object} target - object to copy
         * @return {Object} _target - JSON-copied target object
         *
         * NOTE: this is used when we want to make alterations to an obj w/o altering
         * it or any of it's embedded references
         */
        copyByJson: function copyByJson (target) {
            var _target = JSON.parse(JSON.stringify(target));
            return _target;
        },

        /**
         * Translates a plain js object into URI encoded JSON
         * @param  {Object} obj - obj to translate
         * @return {Object} uriEncodedJson - URI encoded JSON str
         */
        uriEncodeObj: function uriEncodeObj (obj) {

            var uriEncodedJson;

            if (flx.util.isEmpty(obj) || typeof obj !== 'object') {
                console.log('Object URI Encoding Warning: passed in entity was '
                    + 'either empty or not an object');
            } else {
                uriEncodedJson = encodeURIComponent(JSON.stringify(obj));
            }

            return uriEncodedJson;

        },

        /**
         * Takes a JSON string, parses and returns it
         * @param  {String} jsonString - string of JSON data
         * @return {Object} parsedJson - JSON string parsed to a js obj
         */
        safeParse: function safeParse (jsonString) {

            var parsedJson = {};

            try {
                parsedJson = JSON.parse(jsonString);
            } catch (err) {
                console.log('Unable to parse empty or invalid JSON string: ' + jsonString);
            }

            return parsedJson;

        },

        /**
         * Takes a targeted variable and sets it (ONLY) if the passed in value is empty
         * @param  {Any} target - variable to be set and returned
         * @param  {Any} value  - proposed value to set
         * @return {Any} target - variable to be set
         */
        setIfEmpty: function setIfEmpty (target, value) {

            if (flx.util.isEmpty(target)) {
                target = value;
            }

            return target;

        },

        /**
         * Extracts property from all objects in array and returns new array with the values
         * @param  {Array} arr - array to be iterated through
         * @param  {String} targetProp - property name to look for in each object
         * @param  {Boolean} stringify - if true, convert values to strings
         * @return {Array} requestedArr - new array from requested property values
         */
        getFromObjArray: function getFromObjArray (arr, targetProp, stringify) {

            var requestedArr = [];

            for (var i = 0; i < arr.length; i++) {
                if (flx.util.isNotEmpty(arr[i][targetProp])) {
                    stringify ?
                        requestedArr.push(flx.util.safeStringify(arr[i][targetProp]))
                        : requestedArr.push(arr[i][targetProp]);
                }
            }

            return requestedArr;

        },

        /*---------------[Cookie Methods]---------------*/

        /**
         * Retrieves cookie val by cookie name
         * @param  {String} name - name of cookie to fetch value from
         * @return {String | Null} cookieVal - value of fetched cookie or null if no value exists
         */
        getCookie: function getCookie (name) {
            var cookieRegex = new RegExp(name + '=([^;]+)');
            var cookieVal = cookieRegex.exec(document.cookie);
            return (cookieVal !== null) ? unescape(cookieVal[1]) : null;
        },

        /**
         * Set cookie by name, value and expiration date
         * @param {String} name - name of cookie to set
         * @param {String} val - value of cookie you are setting
         * @param {Number} expInDays - how many days until cookie expires
         */
        setCookie: function setCookie (name, val, expInDays) {
            if (typeof expInDays === 'number') {
                var cookieExpiration = new Date();
                cookieExpiration.setDate(cookieExpiration.getDate() + expInDays);
                document.cookie = name + '=' + val + ';expires=' + cookieExpiration + ';path=/;';
            } else {
                if (this.isNotEmpty(expInDays)) {
                    console.log('expInDays should be a number');
                }
                document.cookie = name + '=' + val + ';path=/;';
            }
        },

        /**
         * Delete cookie by name
         * @param {String} cookieName - name of cookie to delete
         */
        deleteCookie: function deleteCookie (cookieName) {
            document.cookie = cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },

        /*---------------[Numeric and Financial Methods]---------------*/

        /**
         * Converts a whole number to a comma separated string
         *
         * @param {Number || String} numToConvert - number to add commas too
         * @returns {String} numStr - numberic string, separated by commas
         */
        numberToComma: function numberToComma (numToConvert) {

            // Init vars
            var numStr = '';

            // Cast a passed-in string to a number
            if (typeof numToConvert === 'string') {
                numToConvert = Number(numToConvert);
            }

            // Early return and warn when trying to comma-separate a NaN value
            if (Number.isNaN(numToConvert)) {

                console.log('numberToComma warning: you are trying to convert NaN to a comma '
                    + 'separated number. Returning null');

                return null;

            }

            numStr = numToConvert.toLocaleString();

            return numStr;

        },

        /**
         * Converts a decimal price into an array with two values: [dollars, cents]
         *
         * @param  {Number||String} priceNonDecimal - should be a number or a numeric string
         * @return {Array} decimalPriceArray - array with price, separated into dollars and cents
         *     - [0] {String} - dollars value
         *     - [1] {String} - cents value
         */
        priceToArray: function priceToArray (priceNonDecimal) {

            // Init vars
            var priceStr;
            var decimalStr;
            var decimalPriceArray;

            // If NaN, empty value, zero or some other random data type, warn and default to 0.00
            if (flx.util.isEmpty(priceNonDecimal)
                || isNaN(priceNonDecimal)
                || priceNonDecimal === 0
                || priceNonDecimal === '0'
                || (typeof priceNonDecimal !== 'number' && typeof priceNonDecimal !== 'string')) {

                console.log('Price conversion warning: priceNonDecimal is either NaN, '
                    + 'zero, an empty value or an invalid data type. Defaulting to 0.00. '
                    + 'Please make sure "' + priceNonDecimal + '" is an acceptable value');

                decimalPriceArray = ['0', '00'];
                return decimalPriceArray;

            }

            // If provide number/numeric string is NOT an int, round it up and log a warning
            if (!Number.isInteger(Number(priceNonDecimal))) {

                console.log('Price conversion warning: trying to convert a decimal '
                    + 'number to a price. Rounding up to a whole number before converting');

                priceNonDecimal = Math.ceil(priceNonDecimal);

            }

            // 'priceNonDecimal' should DEFINITELY be an integer by this point
            // Cast it to a string and slice the whole and decimal portions for array of vals
            priceStr = priceNonDecimal.toString();
            decimalStr = '00' + priceStr.slice(priceStr.length - 2);
            decimalPriceArray = [
                priceStr.slice(0, priceStr.length - 2) || '0',
                decimalStr.slice(decimalStr.length - 2)
            ];

            return decimalPriceArray;

        },

        /**
         * Gets the default multiplier value from hidden input
         * @return {Number} defaultMultiplier - the default lease multiplier
         *
         * NOTES:
         *     - If the value is not available or is empty, log a warning and default it
         *     to 2.35 (console.log will only show on non-production environments)
         */
        getDefaultMultiplier: function getDefaultMultiplier () {

            // Find and cache the DOM element that contains the multiplier
            var $defaultMultInput = $('.js-configDefaultMultiplier');

            // If multiplier info is not available on the front-end, log a warning and
            // default it to 2.35
            if (!($defaultMultInput.length > 0) || flx.util.isEmpty($defaultMultInput.val())) {

                // Log warning in non-prod envs
                if (flx.util.getEnv() !== 'production') {
                    console.log('Multiplier Error: No multiplier provided, defaulting to 2.35');
                }

                // Return a default when not available
                return 2.4;

            } else {

                // Return the true value
                return $defaultMultInput.val();

            }

        },

        /**
         * Caculates the estimated weekly price of a product from it's base price.
         * The estimated weekly price is always rounded up to the nearest dollar.
         * @param {number} basePrice - The base price of the item, formatted as such: $150.00 would appear as 15000
         * @param {number?} multiplier - Lease multiplier
         * @returns {number} the weekly price, formatted as: 16.00 would be 16 (not 1600).
         */
        calculateWeeklyPrice: function calculateWeeklyPrice (basePrice, multiplier) {
            multiplier = multiplier || flx.util.getDefaultMultiplier();
            basePrice = basePrice || 0;
            return Math.ceil((basePrice * multiplier) / 52 / 100) * 100;
        },

        /**
         * Returns the number to the left of the decimal, not rounded
         * @param {Number} price - The price of the item in cents
         * @returns {string} a string of the value converted to dollars
         */
        centsToDollar: function getDollars (price) {

            if (typeof price !== 'number') {
                return price;
            }

            return (price / 100).toFixed(2);
        },

        /*---------------[URI Methods]---------------*/

        /**
         * Lazy man's URI parsing hack
         * @param  {String} uriString - string to parse into a pseudo location object
         * @return {Object} parsedUri - fake location object
         *
         * NOTES:
         *      - This util actually creates an anchor element (which has the same properties
         *      as a location object does)
         */
        parseUri: function parseUri (uriString) {

            var parsedUri = document.createElement('a');

            try {

                parsedUri.href = uriString;
                return parsedUri;

            } catch (err) {

                console.log('URI parser warning: passed in URI [' + uriString + '] may be '
                    + 'malformed or of the wrong data type [' + typeof uriString + ']. '
                    + 'Returning as null');

                return null;

            }

        },

        /**
         * Creates an object from the window's query params
         * @param {String} uriString - optional URI string to parse into a location obj
         * @return {Object} queryObj - An object of all url parameter properties
         */
        getUrlParams: function getUrlParams (uriString) {

            var windowQuery;
            var urlLocation;
            var urlParams = {};

            if (flx.util.isNotEmpty(uriString)) {
                if (typeof uriString === 'string') {
                    urlLocation = flx.util.parseUri(uriString);
                } else {
                    console.log('URL param getter warning: you passed in a uriString with '
                        + 'an incorrect data type [' + typeof uriString + ']. '
                        + 'Please make sure it is a string');
                    return null;
                }
            } else {
                urlLocation = window.location;
            }

            windowQuery = urlLocation.search.substring(1);

            // If query exists
            if (flx.util.isNotEmpty(windowQuery)) {

                var qsFragments = windowQuery.split('&');

                for (var i = 0; i < qsFragments.length; i++) {

                    var queryParamPair = qsFragments[i].split('=');
                    var queryKey = queryParamPair[0];
                    var queryVal = queryParamPair[1];

                    if (typeof urlParams[queryKey] === 'undefined') {
                        urlParams[queryKey] = decodeURIComponent(queryVal);
                    } else if (typeof urlParams[queryKey] === 'string') {
                        urlParams[queryKey] = [
                            urlParams[queryKey],
                            decodeURIComponent(queryVal)
                        ];
                    } else { // Third or later entry with this name.
                        urlParams[queryKey].push(decodeURIComponent(queryVal));
                    }

                }

            }

            return urlParams;
        },

        /**
         * Logs a redirected internal URL to console if it is there
         */
        logRedirectedUrl: function logRedirectedUrl () {

            // Init vars
            var queryParams = flx.util.getUrlParams();
            var redirectedQueryParam = flx.util.extractFromHiddenInput(
                config.targets.redirectedQueryParam
            );

            // If there is a value in the query param, log to the console
            if (flx.util.isNotEmpty(queryParams)
                && flx.util.isNotEmpty(queryParams[redirectedQueryParam])) {

                return console.log(queryParams[redirectedQueryParam]);

            }

            console.log('There is no redirectedUrl in the query params');

        },

        /**
         * Creates a string from a query string object
         * @param {Object} queryObj - URI qs parsed to an object
         * @param {Array} enumPropsList - list of allowable property names
         * @return {String} uriQueryStr - stringified, encoded URI query params
         */
        stringifyUrlParams: function stringifyUrlParams (queryObj, enumPropsList) {

            // Init vars
            var uriQueryStr = '';
            var uriParams = [];

            // If nothing was passed in, return an empty string
            if (flx.util.isEmpty(queryObj)) {
                return uriQueryStr;
            }

            // If a non-empty enum array was passed in, we want to access ONLY the enume'd props
            // in order to stingify and build the qs object (and maintain the order of the props)
            if (flx.util.isNotEmpty(enumPropsList) && Array.isArray(enumPropsList)) {

                // Loop over the enum array
                for (var i = 0; i < enumPropsList.length; i++) {

                    var enumProp = enumPropsList[i];

                    if (flx.util.isNotEmpty(queryObj[enumProp])) {

                        uriParams.push(encodeURIComponent(enumProp) + '='
                            + encodeURIComponent(queryObj[enumProp]));

                    }

                }

                // If no enumPropsList (or an empty or invalid enumPropsList)
                // was pased in, we simply
                // loop over the query object and stringify all of
                // its properties indiscriminately
            } else {

                // Loop over all obj props
                for (var qsProp in queryObj) {

                    uriParams.push(encodeURIComponent(qsProp) + '='
                        + encodeURIComponent(queryObj[qsProp]));

                }

            }

            // If we had extracted 1 or more params, join them into a valid, encoded uri qs,
            // otherwise we return an empty string
            if (flx.util.isNotEmpty(uriParams)) {
                uriQueryStr = '?' + uriParams.join('&');
            }

            return uriQueryStr;

        },

        /*---------------[Micro-Site Methods]---------------*/

        /**
         * Creates or updates the micro-site cookie to the specified vendor-code and exp date
         *
         * @param {String} vendorCode - vendor-code for the micro-site cookie value
         * @param {Number} expInDays - how many days until cookie expires
         * @returns {String} microSiteCookie - cookie value for newly set micro-site cookie
         */
        setMicroSiteCookie: function setMicroSiteCookie (vendorCode, expInDays) {

            var cookieNameInputTarget = config.targets.microSite.cookieName;
            var microSiteCookieName = flx.util.extractFromHiddenInput(cookieNameInputTarget);

            flx.util.setCookie(microSiteCookieName, vendorCode, expInDays || 1);

            return flx.util.getCookie(microSiteCookieName);

        },

        /**
         * Fetches the micro-site cookie if one exists
         * @returns {String} microSiteCookie - micro-site cookie value
         */
        getMicroSiteCookie: function getMicroSiteCookie () {

            var cookieNameInputTarget = config.targets.microSite.cookieName;
            var microSiteCookieName = flx.util.extractFromHiddenInput(cookieNameInputTarget);

            return flx.util.getCookie(microSiteCookieName);

        },

        /**
         * Deletes the currently set micro-site cookie if one exists
         */
        deleteMicroSiteCookie: function deleteMicroSiteCookie () {

            var cookieNameInputTarget = config.targets.microSite.cookieName;
            var microSiteCookieName = flx.util.extractFromHiddenInput(cookieNameInputTarget);

            flx.util.deleteCookie(microSiteCookieName);

        },
    };

    // Init env setting
    flx.util.setEnv();
    flx.util.setRfkStatus();
    flx.util.setDeviceType();
    flx.util.killBfCache();

    // Handle waterfall-specific UI updates for customer
    $(function() {
        $.ajax({
            url: window.location.origin + '/waterfall',
            method: 'GET',
            dataType: 'json',
        })
            .done(function (response) {
                var waterfallEnabled = response.enabled;
                var lenderInfo = response.lenderInfo;
                var $body = $(config.targets.body);

                // Set waterfall classnames to body
                if (waterfallEnabled) {
                    $body.addClass(config.classNames.waterfallEnabled);
                }
                if (lenderInfo) {
                    $(config.targets.body).addClass(
                        config.classNames.isWaterfallUser + ' ' +
                        config.classNames.waterfallLender + lenderInfo.keyName
                    );
                }

                // Reveal the global banner after classnames are added to prevent any sort of flickering or swapping
                flx.util.showGlobalBanner();
            })
            .catch(function (error) {
                console.error('Failed to get waterfall info.', { error: error });
        });
    });

    // Add fast click
    $(function attachFastClick () {
        FastClick.attach(document.body);
    });

    // run code based on referral params
    $(function referralParams() {
        var params = flx.util.getUrlParams();
        if (params && params.do) {
            switch (params.do) {
                case 'pp3':
                    if (flx.util.isEmpty(flx.acct.getCustomerStats())) {
                        flx.acct.fpayRedirect('SIGNUP')(new Event(''));
                    }
                default:
            }
        }
    });

    //setting loggedin cookier for logged in customers to control fastly cache
    $(function addLoggedinCookie() {
        try
        {
            var item = window.localStorage.getItem('customer-stats');
            if(!item){
                flx.util.deleteCookie('loggedin'); 
                return;
            }
            var customerStats = JSON.parse(item);
            if(customerStats.approved){
                var cookie = flx.util.getCookie('loggedin');
                if(!cookie){
                    flx.util.setCookie('loggedin', 'yes', 30);
                }
            }
            else
            {
                flx.util.deleteCookie('loggedin');
            }
        }
        catch(err){
            console.error('error in creating loggedin cookie', err);
        }
    });

})();
