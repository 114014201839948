(function () {

    /**
     * MiniCart Module
     * @param {Object} options - options object for obj initialization
     */
    function MiniCart (options) {

        /****************************************
         * Properties
         ****************************************/

        var currPage;
        var miniCartTimeout;
        var pgCount;
        var itemsPerPage;
        var paginationMinHeight;
        var cookieName = options.cookie.name;
        var cookieExp = options.cookie.expInDays;
        var targets = options.targets;
        var hasItemsClass = 'hasItems';


        /****************************************
         * jQ Objects
         ****************************************/

        var $flyoutBtn;
        var $flyoutBtnQty;
        var $flyout;
        var $miniCartLabel;
        var $itemsWrap;
        var $items;
        var $itemRemoveBtn;
        var $mcCheckoutBtn;
        var $paginationWrap;
        var $paginationBtns;
        var $paginationPrev;
        var $paginationNext;
        var $paginationLabel;

        /**
         * Re-caches jQuery objects
         * - Used on page load and on mini-cart update
         */
        function cacheJqTargets () {

            // Flyout
            $flyoutBtn = $(targets.btn);
            $flyoutBtnQty = $flyoutBtn.find('.miniCartQty');
            $miniCartLabel = $flyoutBtn.find('.miniCartLabel');
            $flyout = $flyoutBtn.find(targets.flyout);

            // Items
            $itemsWrap = $(targets.items.wrap);
            $items = $itemsWrap.find(targets.items.item);
            $itemRemoveBtn = $flyout.find(targets.removeItem);

            // Checkout
            $mcCheckoutBtn = $flyout.find(targets.checkoutBtn);

            // Pagination
            $paginationWrap = $flyout.find(targets.pagination.wrap);
            $paginationBtns = $paginationWrap.find(targets.pagination.btns);
            $paginationPrev = $paginationWrap.find(targets.pagination.prev);
            $paginationNext = $paginationWrap.find(targets.pagination.next);
            $paginationLabel = $paginationWrap.find(targets.pagination.label);

        };


        /****************************************
         * Methods
         ****************************************/

        /*----[General Methods]-----*/

        /**
         * Binds handlers for various mini-cart functionality
         *
         * The following actions are bound here:
         * - Flyout show and hide
         * - Remove item from mini-cart
         * - Successful update fires getMiniCartItems
         * - Pagination controls
         */
        function setHandlers (options) {

            // Show / hide menu
            $flyoutBtn.on({
                'mouseenter.mcFlyout': showMiniCart($flyout),
                'mouseleave.mcFlyout': hideMiniCart($flyout)
            });

            // Remove items from cart btn
            $flyout.on('click.removeMcItem', targets.removeItem, removeItemFromMiniCart);

            // When cart is updated fetch updated items for mini-cart
            $(document).on('cartUpdate', getMiniCartItems);

            // Pagination btns
            setCheckoutBtn();
            setPaginationControls();

        };

        /**
         * Binds click handler for mini-cart checkout btn
         *
         * NOTES:
         *     - Will not allow user to checkout if cart limit status !== 'OK'
         *     - Shows different error messaging by
         */
        function setCheckoutBtn () {
            $mcCheckoutBtn.on({
                'click.mcCheckoutBtn': flx.cart.toCheckout
            });
        };

        /**
         * Updates cart details in closure scope for use by mini-cart
         * - flx.cart.details
         * - pgCount
         * - itemsPerPage
         * @param {Object} dataSource - cart details
         */
        function setMiniCartDetails (dataSource) {

            // If no data source, save as empty obj
            dataSource = flx.util.isNotEmpty(dataSource)
                ? dataSource
                : {};

            flx.cart.setDetails(dataSource);
            pgCount = flx.cart.details.pagination.pgCount;
            itemsPerPage = flx.cart.details.pagination.itemsPerPage;

        };

        /**
         * Show mini-cart on hover
         * @param  {jQ Object} $flyout - wrapper elem for flyout
         * @return {Fn} - curried fn that clears / sets hover timeout
         */
        function showMiniCart ($flyout) {
            return function () {
                clearTimeout(miniCartTimeout);
                miniCartTimeout = setTimeout(function () {
                    $flyout.fadeIn(100);
                }, 200);
            };
        };

        /**
         * Hide mini-cart on hover out
         * @param  {jQ Object} $flyout - wrapper elem for flyout
         * @return {Fn} - curried fn that clears / sets hover timeout
         */
        function hideMiniCart ($flyout) {
            return function () {
                clearTimeout(miniCartTimeout);
                miniCartTimeout = setTimeout(function () {
                    $flyout.fadeOut(100);
                }, 200);
            };
        };

        /*----[Pagination Methods]-----*/

        /**
         * Click handler for 'Prev' pagination btn
         * - Detach click handlers to avoid queuing up multiple page changes
         * - Sets and shows currPage
         * - Reattaches pagination controls
         */
        function nextPage () {
            detachPaginationHandlers('both');
            setCurrPage(currPage + 1);
            showPgByNum(currPage);
            setPaginationControls();
        };

        /**
         * Click handler for 'Prev' pagination btn
         * - Detach click handlers to avoid queuing up multiple page changes
         * - Sets and shows currPage
         * - Reattaches pagination controls
         */
        function prevPage () {
            detachPaginationHandlers('both');
            setCurrPage(currPage - 1);
            showPgByNum(currPage);
            setPaginationControls();
        };

        /**
         * Enables btn(s) and binds click handler for targeted pagination control(s)
         * - whichToAttach param must be a non-empty string
         * @param  {String} whichToAttach - 'next', 'prev' or 'both'
         */
        function attachPaginationHandlers (whichToAttach) {

            // Make sure user passes whichToAttach param and it is a string
            if (flx.util.isEmpty(whichToAttach) || typeof whichToAttach !== 'string') {
                return console.log('You must provide whichToAttach arg and it must be a string');
            }

            // Binds handlers to prev, next or both pagination btns
            var attachHandlers = {
                prev: function () {
                    $paginationPrev
                        .removeClass('disabled')
                        .on('click.miniCartPagination', prevPage);
                },
                next: function () {
                    $paginationNext
                        .removeClass('disabled')
                        .on('click.miniCartPagination', nextPage);
                },
                both: function () {
                    $paginationBtns.removeClass('disabled');
                    $paginationPrev.on('click.miniCartPagination', prevPage);
                    $paginationNext.on('click.miniCartPagination', nextPage);
                }
            };

            // Call specified attach method
            attachHandlers[whichToAttach]();

        };

        /**
         * Disables btn(s) and unbinds click handler for targeted pagination control(s)
         * - whichToDetach param must be a non-empty string
         * @param  {String} whichToDetach - 'next', 'prev' or 'both'
         */
        function detachPaginationHandlers (whichToDetach) {

            // Make sure user passes whichToDetach param and it is a string
            if (flx.util.isEmpty(whichToDetach) || typeof whichToDetach !== 'string') {
                return console.log('You must provide whichToDetach arg and it must be a string');
            }

            // Unbinds handlers from prev, next or both pagination btns
            var detachHandlers = {
                prev: function () {
                    $paginationPrev
                        .addClass('disabled')
                        .off('click.miniCartPagination');
                },
                next: function () {
                    $paginationNext
                        .addClass('disabled')
                        .off('click.miniCartPagination');
                },
                both: function () {
                    $paginationBtns
                        .addClass('disabled')
                        .off('click.miniCartPagination');
                }
            };

            // Call specified detach method
            detachHandlers[whichToDetach]();

        };

        /**
         * Set height of mini-cart items first time user has enough items to trigger pagination
         * - Should only fire once on a given pg
         * - No explicit height in CSS as height will change if itemsPerPage config changes
         * - NOTE: needs to show, then hide the flyout or won't be able to calc height
         */
        function setPaginationMinHeight () {
            $flyout.show(0);
            showPgByNum(1); // Since pg 1 will always have a full set of paginated items
            paginationMinHeight = $itemsWrap.outerHeight();
            $itemsWrap.css({
                'min-height': paginationMinHeight
            });
            $flyout.hide(0);
        };

        /**
         * Sets up pagination controls based on current items count
         * - Shows pagination controls if and only if more than 1 pg in pgCount
         * - If on last pg, detaches "Next" pagination control
         * - If on first pg, detaches "Prev" pagination control
         * - Otherwise, attaches both controls
         * - Sets paginationMinHeight
         */
        function setPaginationControls () {

            // Default to no-pagination
            $paginationWrap.removeClass('enabled');
            detachPaginationHandlers('both');

            // Bind handlers and change DOM if pagination enabled
            if (pgCount > 1) {

                $paginationLabel.text('Page ' + currPage);
                $paginationWrap.addClass('enabled');

                // Set pagination handlers
                if (currPage === pgCount) {
                    attachPaginationHandlers('prev');
                } else if (currPage === 1) {
                    attachPaginationHandlers('next');
                } else {
                    attachPaginationHandlers('both');
                }

                // Set paginated minHeight the first time pagination is enabled
                if (typeof paginationMinHeight === 'undefined') {
                    setPaginationMinHeight();
                } else {
                    $itemsWrap.css({
                        'min-height': paginationMinHeight
                    });
                }

            }

        };

        /**
         * Retrieves cookie val for current mini-cart page
         * - Cookie name to retrieve is set in init config options object
         * @return {Number} num - page number for current mini-cart page
         */
        function getCurrPage () {
            return Number(flx.util.getCookie(cookieName));
        };

        /**
         * Sets cookie for current mini-cart page and caches it in currPage
         * - Checks num and ensures that currPage will never be set to < 1
         * - Cookie expiration date and cookie name are set in init options object
         * @param {Number} num - page number
         */
        function setCurrPage (num) {

            // Make sure num param is a number (not a string)
            if (!num || typeof num !== 'number' || Number.isNaN(num)) {
                currPage = 1;
                flx.util.setCookie(cookieName, 1, cookieExp);
                return console.log('\'num\' param must be a number for us to set currPage. '
                    + 'Defaulting to pg 1');
            }

            // If num is less than 1, default it to 1
            if (num < 1) {
                flx.util.setCookie(cookieName, 1, cookieExp);
                currPage = 1;
            } else {
                if (num > pgCount) { // We never want to set currPg greater than pgCount
                    flx.util.setCookie(cookieName, pgCount, cookieExp);
                    currPage = pgCount;
                } else {
                    flx.util.setCookie(cookieName, num, cookieExp);
                    currPage = num;
                }
            }

        };

        /**
         * Shows the paginated mini-cart items for given page
         * @param {Number} pgNum - mini-cart pg number
         */
        function showPgByNum (pgNum) {

            var itemLoopStart = itemsPerPage * (pgNum - 1);
            var itemLoopEnd = itemLoopStart + itemsPerPage;

            // Hide all items
            $items.removeClass('active');

            // Show items for given page
            for (var i = itemLoopStart; (i < itemLoopEnd && i < $items.length); i++) {
                $items.eq(i).addClass('active');
            }

        };

        /*----[Content Methods]-----*/

        /**
         * Functionality to remove item from mini-cart
         * - Copies cart details obj and passes it to processForUpdate()
         * - NOTE: processForUpdate removes item by skuId and maps cart obj for db update
         * - If query succeeds, updates global cart details, rebinds click and triggers cartUpdate
         * @param  {Event} e - event object
         */
        function removeItemFromMiniCart (e, spinnerId) {

            var skuId = $(this).attr('sku-id');
            var _cartDetails = $.extend(true, {}, flx.cart.details); // Deep copy of obj
            var cartItemId = $(this).attr('cart-item-id');

            var productInfo = {
                desc: $(this).attr('sku-desc'),
                slug: $(this).attr('product-slug'),
            };
            var eventTitle = 'Product Removed';

            // Unbind handler until processing completes
            $flyout.off('click.removeMcItem');

            // Show spinner to indicate the page is being updated
            var spinnerId = flx.spinner.show();

            // Map cart for db update
            var cart = flx.cart.processForUpdate(_cartDetails, skuId);

            // Execute removal query
            flx.cart
                .removeFromCart(cartItemId)
                .done(function (cartData) {

                    // Rebind remove item handler
                    $flyout.on('click.removeMcItem', targets.removeItem, removeItemFromMiniCart);
                    flx.cart.details = _cartDetails;

                    $(document).trigger('cartUpdate', [spinnerId, productInfo, 'cart_remove']);

                    flx.analytics.sendSegmentAnalytics(cartData.analyticsTrack, eventTitle);

                })
                .fail(function fail (xhr, errDesc, err) {

                    var failMsg = 'Unable to remove item from mini-cart';
                    $(document).trigger('cartUpdateError', [failMsg, spinnerId]);

                    // Rebind remove item handler
                    $flyout.on('click.removeMcItem', targets.removeItem, removeItemFromMiniCart);

                });

            document.location.reload(true)
        };

        /**
         * Fetches updated mini-cart items when cart is updated sucessfully
         * @param  {Object} e - "cartUpdate" event object
         */
        function getMiniCartItems (e, spinnerId, eventType) {
            $.ajax({
                url: '/mini-cart/ajax/updatedItems',
                method: 'GET',
                dataType: 'json'
            })
                .done(function (cartData) {

                    // Wait til mini-cart items are fetched, then hide spinner, when on desktop
                    if (flx.util.getDeviceType() === 'desktop') {
                        flx.spinner.hideById(spinnerId);
                    }

                    $flyout.html(cartData.renderedMiniCart);
                    cacheJqTargets();
                    setMiniCartDetails(cartData.cartDetails); // Update details
                    $flyoutBtnQty.html(flx.cart.details.itemTotal);
                    if (flx.cart.details.itemTotal > 0) {
                        $flyoutBtn.addClass(hasItemsClass);
                    }
                    setCurrPage(currPage);
                    setPaginationControls();
                    setCheckoutBtn();
                    flx.cart.showCartLimitAlert();
                    flx.cart.updateCheckoutBtnStatus();
                    showPgByNum(currPage);

                })
                .fail(function (jqXHR, status, errMsg) {
                    if (errMsg && errMsg.length) {
                        var failMsg = 'Unable to get updated mini-cart items';
                        $(document).trigger('cartUpdateError', [failMsg, spinnerId]);
                    }
                });
        };

        /**
         * Initialize properties and event handlers for mini-cart
         * - Caches mini-cart jq selectors
         * - Caches cart details
         * - Sets currPg cookie if not already set
         * - Binds handlers and shows items
         */
        function init () {
            cacheJqTargets();
            $flyout.slideUp(); // Slide up on pg load
            setMiniCartDetails(flx.cart.details);
            setCurrPage(getCurrPage());
            setHandlers(options);
            showPgByNum(currPage || 1);
        };

        // Public interface
        return {
            getMiniCartItems: getMiniCartItems,
            init: init,
        };

    };

    // Initialize minicart obj if needed
    if (typeof flx.miniCart === 'undefined') {
        (function instantiateMiniCart () {

            var miniCartOptions = {
                cookie: {
                    name: 'minicart_curr_pg',
                    expInDays: 1
                },
                targets: {
                    btn: '.miniCartBtn',
                    checkoutBtn: '.miniCartCheckout',
                    flyout: '.miniCartFlyout',
                    removeItem: '.removeSku',
                    pagination: {
                        wrap: '.paginationWrap',
                        btns: '.paginationBtns',
                        prev: '.paginationBtns.prev',
                        next: '.paginationBtns.next',
                        label: '.mcPageLabel'
                    },
                    items: {
                        wrap: '.cartSkus',
                        item: '.skuWrap'
                    },
                    details: '#js-miniCartDetails'
                }
            };

            // Instantiate mini-cart and cache it globally
            flx.miniCart = new MiniCart(miniCartOptions);

        })();
    }

    flx.miniCart.init();

})();
