(function () {

    // Config
    var config = {
        targets: {
            overlay: {
                basic: '.flxOverlay.coverAll',
                modal: '.flxOverlay.modalOl',
                spinner: '.flxOverlay.spinnerOverlay',
                megaMenu: '.flxOverlay.mmOverlay',
                mobiMegaMenu: '.flxOverlay.mobiMmOverlay'
            }
        },
        elems: {
            overlay: {
                basic: '<div class="flxOverlay coverAll"></div>',
                modal: '<div class="flxOverlay modalOl"></div>',
                spinner: '<div class="flxOverlay spinnerOverlay"></div>',
                megaMenu: '<div class="flxOverlay mmOverlay"></div>',
                mobiMegaMenu: '<div class="flxOverlay mobiMmOverlay"></div>'
            }
        }
    };

    flx.overlays = {

        /**
         * Gets ALL overlay elements on the page
         * @return {jQ Object} $allOlElems - jQuery object of all matching overlay elems
         *
         * NOTES:
         *     - Uses the configured target selector strings to grab all elems on the page
         */
        getAllDomElems: function getAllDomElems () {

            var allOlTargets = flx.overlays.getAllTargets();
            var allOlTargetsCombined = allOlTargets.join(', ');
            var $allOlElems = $(allOlTargetsCombined);

            return $allOlElems;

        },

        /**
         * Gets ALL target selector-strings from configuration
         * @return {Array} allOlTargets - list of selector strings pulled from configuration
         */
        getAllTargets: function getAllTargets () {

            var olTargetsMap = config.targets.overlay;
            var allOlTargets = [];

            for (var olTargetType in olTargetsMap) {
                allOlTargets.push(olTargetsMap[olTargetType]);
            }

            return allOlTargets;

        },

        /**
         * Removes all overlay DOM elements from the page
         * @param  {Boolean} includingModals - remove modal-type overlays too
         * @return {jQ Object} $allOlElems - grabs all overlay DOM elems and removes them
         */
        removeAllDomElems: function removeAllDomElems (includingModals) {

            var overlayClass = '.' + config.targets.overlay.modal.substr(1).split('.')[1];
            var $allOlElems = flx.overlays.getAllDomElems();

            if ($allOlElems.length > 0) {
                if (includingModals) {
                    $allOlElems.remove();
                } else {
                    $allOlElems.not(overlayClass).remove();
                }
            }

            return $allOlElems;

        },

        /**
         * Fetches HTML string from olElemsMap by passed in overlay-type
         * @param  {String} type - type of overlay, identified as a string
         * @return {String} olElemHtml - HTML string of correct overlay-type
         */
        getElemHtmlByType: function getElemHtmlByType (type) {

            var olElemsMap = config.elems.overlay;
            var olElemHtml = olElemsMap[type];

            // If no matching target with passed-in type, log a warning to the console
            if (flx.util.isEmpty(olElemHtml)) {

                console.log('Overlays warning: can\'t fetch elem HTML by type of: [' + type
                + ']. Setting to default elem HTML');

                olElemHtml = olElemsMap.basic;

            }

            return olElemHtml;

        },

        /**
         * Adds correct overlay HTML elem to the DOM by overlay-type
         * @param  {String} type - type of overlay, identified as a string
         * @param  {jQ Object} $target - (optional) elem to place the DOM elem into
         * @return {jQ Object} $olElem - HTML elem, newly placed into the DOM
         */
        addDomElemByType: function addDomElemByType (type, $target) {

            var olElemHtml = flx.overlays.getElemHtmlByType(type);
            var $olElem = $(olElemHtml);

            // If user passes jQuery elem, place there, otherwise place in body
            if (flx.util.isJQueryElem($target)) {
                $target.prepend($olElem);
            } else {
                $('body').prepend($olElem);
            }

            return $olElem;

        },

        /**
         * Accepts a string for overlay-type, clears ALL existing overly elems, then creates a new
         * overlay based on passed-in overlay-type
         *
         * @param  {String} type - type of overlay, identified as a string
         * @param  {jQ Object} $target - (optional) elem to place the DOM elem into
         */
        clearAndCreateByType: function clearAndCreateByType (type, $target) {

            flx.overlays.removeAllDomElems();
            flx.overlays.addDomElemByType(type, $target);

        }

    };

})();
