/*
    This plugin is used to open a modal window populated with AJAX or
    in-browser data.

    ************************************************************************
    You can pass several options as an obj when invoking the plugin:

        - 'modalSelector' is used to select the outer shell / window of
        the modal window.

        - 'deviceType' is used to set different modal layouts for mobile and
        desktop. "Mobile" sets modal with 100% width and 15px on left and right
        paddings. "Desktop" sets left value to half of global modal width to
        center the modal.

        - 'ajax' is an object used for building AJAX calls that will
        populate the modal window html. It has two properties, 'url' for the
        AJAX url and 'fail' for the AJAX error callback.

        - 'inBrowser' is a selector string which is used to populate the
        modal with html that is cached in-browser, rather than from an AJAX
        call.

        - 'closeBtn' is a selector string for the close btn that the close
        handlers get bound to. Override this setting if your close-btn will
        have different markup than the default.

        - 'outerClass' is a string that applies an outer class to the modal
        window. It gets removed on close to avoid affecting other modals.

        - 'maxWidth' self-explanatory

        - 'position' is a string. It can be either 'fixed' or 'absolute'.
        If set to fixed, the modal will be resized to be smaller than the
        browser window and given overflow: hidden. This avoids chopping off
        part of the modal.

        - 'topBtmOffset' is an integer that defines the top and bottom offset
        of the modal window.

        - 'openEvent' is the event which will be fired after modal is opened.
        Event name could be overidden and the custom made event will be fired.
        e.g. 'globalModalOpen.zipSearch' event from search page will be running
        after Zip Code global is opened.

        - 'closeEvent' is the event which will be fired after modal is closed.
        Event name could be overidden and the custom made event will be fired.
        e.g. 'globalModalClose.zipSearch' event from search page will be running
        after Zip Code global is closed.

    ************************************************************************
    NOTE: AJAX content gets cached to avoid further hits to the server.

    When the modal is opened, there are 5 event handlers bound and the click
    handler for opening is unbound.

        1. Modal close button
        2. ESC button to close modal
        3. Clicking outside of modal closes it
        4. Clicking inside modal doesn't close it
        5. Adjust modal on window resize

    When the modal is closed, the 5 close handlers are unbound and the
    click handler to open the modal is re-bound.
 */

(function () {
    $.fn.globalModal = function (options, openOnInit) {

        /*---------[Options / Defaults]----------*/

        var defaults = {
            allOver: false,
            lockBackground: false,
            modalSelector: '.globalModal',
            deviceType: 'desktop',
            ajax: null,
            inBrowser: openOnInit ? this : null,
            closeBtn: '.modalCloseBtn',
            outerClass: null,
            maxWidth: null,
            position: 'absolute',
            topBtmOffset: 20,
            mobileSideOffset: '5%',
            mobileTopBtmOffset: '5%',
            openEvent: 'globalModalOpen',
            closeEvent: 'globalModalClose'
        };

        var settings = $.extend({}, defaults, options);

        // Warn user when position is set to something other than 'absolute' or 'fixed'.
        // Then, set it to default
        if (settings.position !== 'absolute' && settings.position !== 'fixed') {

            // Warning
            console.log('Position setting for global modal should be set to either'
                + ' \'absolute\' or \'fixed\'. You set it to \'' + options.position + '\'. '
                + '\n\nDefaulting to \'' + defaults.position + '\'.');

            // Set to default
            settings.position = defaults.position;

        }


        /*---------[Vars / Selectors]----------*/

        var $this = this;
        var $modal = $(settings.modalSelector);
        var $closeBtn = $modal.find(settings.closeBtn);
        var ajaxCache = {};
        var isAbsolute = (settings.position === 'absolute');
        var device = settings.deviceType;

        /*---------[Functions]----------*/

        /**
         * Locks background scrolling
         *
         * NOTES:
         *      - Is called when lockBackground is set to true and using fixed positioning
         */
        function lockBackground () {

            if ((settings.lockBackground && settings.position === 'fixed') || settings.allOver) {
                $('body').css({
                    overflow: 'hidden'
                });
            }

        }

        /**
         * Reenables background scrolling
         *
         * NOTES:
         *      - Is called when lockBackground is set to true and using fixed positioning
         */
        function unlockBackground () {

            if ((settings.lockBackground && settings.position === 'fixed') || settings.allOver) {
                $('body').css({
                    overflow: ''
                });
            }

        }

        /**
         * Positioning, sizing and overflow for modal window
         */
        function applyModalPosition () {

            if (settings.allOver) {

                $modal.css({
                    position: 'fixed',
                    top: '10px',
                    bottom: '10px',
                    right: '10px',
                    left: '10px',
                    overflow: 'auto'
                });

            } else {

                $modal.css({
                    position: settings.position,
                    'max-height': $(window).height() - (2 * settings.topBtmOffset) + 'px',
                    height: '',
                    top: settings.topBtmOffset + 'px',
                    overflow: 'auto'
                });

                // On desktop, horizontally center modal window
                if (device == 'desktop') {
                    $modal.css({
                        'max-width': settings.maxWidth,
                        left: ($(window).width() - parseInt($modal.width())) / 2 + 'px'
                    });
                }

                // Set distance from sides on mobile
                if (device == 'mobile') {
                    $modal.css({
                        left: settings.mobileSideOffset,
                        right: settings.mobileSideOffset
                    });
                }

            }

        }

        /**
         * Calculates the div height for absolutely positioned modals
         *
         * NOTES: Temporarily unused - There is a bug with this method that prevents it from
         * returning the correct height due to unloaded content before executing. The window will
         * sometimes be chopped upon loading or refreshing the page. Usually on first load before
         * assets are cached. Replaced with max-height style property.
         *
         * TO DO: Add an asset pre-loader and add the option to delay those modals until assets
         * are fully loaded.
         *
         * @param  {jQ elem} $modal - the modal DOM element
         * @return {String} absoluteModalHeight - px dimensions for modal height
         */
        function calcAbsoluteModalHeight ($modal) {

            // Normalize height back to unclipped
            $modal.css({height: ''});

            // Init vars
            var unclippedModalHeight = $modal.outerHeight();
            var maxModalHeight = $(window).height() - (2 * settings.topBtmOffset);
            var absoluteModalHeight = Math.min(unclippedModalHeight, maxModalHeight);

            return absoluteModalHeight + 'px';

        }

        /**
         * Return curried openModal fn
         * @param  {Boolean} isAjax - denotes whether firing for AJAX or not
         * @return {Fn} openModalCurried - curried fn for modal open
         */
        function openModal (isAjax) {

            /**
             * Replaces modal html content, adds outerClass (if provided) and shows modal window
             * @param  {String} html - string of html for modal content
             */
            return function openModalCurried (html) {

                // If firing an AJAX call, caching is enabled AND results aren't already cached,
                // then cache the results
                if (isAjax === true
                    && !ajaxCache[settings.ajax.url]
                    && !Boolean(settings.ajax.cacheDisabled)) {

                    ajaxCache[settings.ajax.url] = html;

                }

                // Replace html content
                $modal.html(html);

                // Add outerClass if provided
                if (typeof settings.outerClass === 'string') {
                    $modal.addClass(settings.outerClass);
                }

                // Show modal
                $modal.addClass('open');
                flx.overlays.clearAndCreateByType('modal');
                applyModalPosition();
                lockBackground();

                // Fire event defined in options hash
                $(document).trigger(settings.openEvent);

                // Bind close handlers
                bindClose();

            };
        }

        /**
         * Closes modal window and binds open handlers
         */
        function closeModal () {

            // Hide Modal
            $modal.removeClass('open');
            flx.overlays.removeAllDomElems(true);

            // Remove all classes, except base class
            $modal.attr('class', settings.modalSelector.slice(1));
            unlockBackground();

            // Fire event defined in options hash
            $(document).trigger(settings.closeEvent);

            // Bind open handlers
            bindOpen();

        }

        /**
         * Retrieve modal contents via AJAX
         * @param  {Object} ajax - taken from settings.ajax
         */
        function ajaxRetrieve (ajax) {

            // Default AJAX fail back fn
            function ajaxFail (xhr, status, err) {
                console.dir(err);
            }

            // Set err callback
            var errBack = Boolean(ajax) ?
                (ajax.fail || ajaxFail) : ajaxFail;

            // Null check for ajax.url
            if (!Boolean(ajax.url)) {
                return console.log('No AJAX URL provided!');
            }

            // Search AJAX call for previously retrieved contents
            // If they exist, use them
            if (ajaxCache[ajax.url]) {
                openModal(null)(ajaxCache[ajax.url]);
            } else {
                $.ajax({
                    url: ajax.url
                })
                    .done(openModal(true))
                    .fail(errBack);
            }
        }

        /**
         * Get the modal content and open it
         */
        function triggerModal () {

            // If defined, fire the AJAX call
            if (settings.ajax !== null) {

                ajaxRetrieve(settings.ajax);

            } else { // Else look for other sources (inBrowser)

                // Null check for inBrowser selector
                if (!settings.inBrowser) {
                    console.log('No source for modal content!');
                } else {
                    // If "openOnInit" is true, "inBrowser" will be the modal element itself.
                    // Otherwise it'll be a selector for the target modal content.
                    var html = typeof settings.inBrowser == 'object'
                        ? settings.inBrowser.html()
                        : $(settings.inBrowser).html() || $(settings.inBrowser).val();

                    // Launch it
                    openModal(null)(html);
                }

            }
        }

        /**
         * Click handler for bound dialog btn
         * @param  {Object} e - click event
         */
        function modalClick (e) {

            e.preventDefault();
            e.stopPropagation(); // Stops accidental firing of 'click.modalOpen'

            triggerModal();
        }


        /*---------[Event Handlers]----------*/

        /**
         * Bind open handlers
         */
        function bindOpen () {
            unbindOpen();
            unbindClose(); // Unbind close handlers (if already set)
            $this.on({
                'click.openModal': modalClick
            });
        }

        /**
         * Unbind open handlers
         */
        function unbindOpen () {
            $this.off('click.openModal');
        }

        /**
         * Click handlers for modal
         * 1. Close Button
         * 2. Pressing ESC close the modal
         * 3. Clicking outside of modal closes it
         * 4. Clicking INSIDE modal doesn't close
         * 5. Re-adjust modal position when resizing the window
         */
        function bindClose () {

            unbindOpen(); // Unbind OPEN

            // Close btn closes modal
            $modal.on({
                click: function (e) {
                    closeModal();
                }
            }, settings.closeBtn); // Using delegation for dynamic elem

            $(document).on({

                // Pressing ESC closes the modal
                'keydown.modalOpen': function (e) {
                    if (e.keyCode == 27) {
                        closeModal();
                    }
                },

                // Clicking outside of modal closes it
                'click.modalOpen': function () {
                    closeModal();
                },

                // Allow modal close to be triggered from other scripts
                triggeredCloseModal: function () {
                    closeModal();
                }

            });

            // Clicking inside of modal DOES NOT close it
            $modal.on({
                click: function (e) {
                    e.stopPropagation();
                }
            });

            // Bind window resize to adjust modal window position
            $(window).on({
                'resize.setModal': flx.util.debounce(applyModalPosition, 50)
            });

        }

        /**
         * Unbind close handler
         */
        function unbindClose () {
            $closeBtn.off('click');
            $modal.off('click');
            $(document).off('click.modalOpen'); // Unbind click from namespaced event
            $(document).off('keydown.modalOpen'); // Unbind keypress from namespaced event
            $(document).off('resize.setModal'); //  Unbind resize from namespaced event
        }

        // Setup modal window position on initial load
        applyModalPosition();

        // Bind on initial load
        if (openOnInit === true) {
            triggerModal();
        } else {
            bindOpen();
        }

        return this;
    };
})();
