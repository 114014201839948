(function iifeAnalytics () {

    /*----------[Module Configuration]----------*/

    var config = {
        accessKeys: {
            sessionStorage: {
                pageViews: 'flx.fmweb.pageViews'
            },
            localStorage: {
                stamps: {
                    first: 'flx.fmweb.firstViewed',
                    last: 'flx.fmweb.lastViewed'
                }
            }
        },
        targets: {
            cookieDomain: '.js-cookieDomain',
            boldChatClick: '.bcFloat, #bc-chat-container'
        }
    };

    var Analytics = flx.analytics = {

        /*---------------[Properties]---------------*/

        state: {
            isPgCountIterated: false,
            areStampsCached: false,
            stamps: {
                first: null,
                last: null,
                current: null
            }
        },

        /*---------------[Segment Methods]---------------*/

        /**
         * Calls the segment dataTrack method
         * @param  {Object} analyticsTrack - data object for tracking
         * @param  {String} eventTitle - name of the Segment evt to track
         */
        sendSegmentAnalytics: function sendSegmentAnalytics (analyticsTrack, eventTitle) {
            window.dataTrack = analyticsTrack;
            analytics.track(eventTitle, window.dataTrack);
        },

        /**
         * Checks DOM for user data and when available makes the relevant analytics identify call
         *
         * - NOTES:
         *      This requires a kongCustomerId to present (which should be if user logs in)
         */
        segmentIdentify: function segmentIdentify () {

            // Init vars
            var dataForIdentifyCall = {};
            var kongCustomerId = flx.util.getKongCustomerId();
            var userData = flx.util.getUserData();

            // Checks for customer id, must be present for us to even make the identity call
            if (flx.util.isNotEmpty(kongCustomerId)) {

                // Add email for call if available
                if (flx.util.isNotEmpty(userData.email)) {
                    dataForIdentifyCall.email = userData.email;
                }

                // Make the call to Segment for identity
                analytics.identify(kongCustomerId, dataForIdentifyCall);

            }

        },

        /*---------------[Page View Tracking Methods]---------------*/

        /**
         * Fetches pagesViewedCount from in-browser storage
         * @return {Number} pagesViewedCount - count of pgs viewed, pulled from storage
         */
        fetchPageViewsFromStorage: function fetchPageViewsFromStorage () {

            // Fetch the current value from storage (always comes as back as a string)
            var pagesViewedCount = flx.util.fetchFromStorage(
                'sessionStorage',
                config.accessKeys.sessionStorage.pageViews,
                0
            );

            // If there was a stored count, parse it to an integer, otherwise set it to zero
            return (flx.util.isNotEmpty(pagesViewedCount)) ? parseInt(pagesViewedCount, 10) : 0;

        },

        /**
         * Iterates the user's count of pages viewed in the current session and saves in storage
         * @return {Number} pagesViewedCount - iterated pages viewed count
         */
        iteratePagesViewed: function iteratePagesViewed () {

            // If sessionStorage is unavailable, early return and do nothing
            if (!flx.util.hasStorageCapability('sessionStorage')) {
                return null;
            }

            // Fetch the count and iterate it
            var pagesViewedCount = Analytics.fetchPageViewsFromStorage() + 1;

            // Update the stored count
            sessionStorage.setItem(config.accessKeys.sessionStorage.pageViews, pagesViewedCount);

            // Mark the count as iterated
            Analytics.state.isPgCountIterated = true;

            return pagesViewedCount;

        },

        /**
         * Fetches the user's page view count, iterating the return value if necessary
         * @return {Number} pagesViewedCount - pages viewed count
         *
         * NOTES:
         *      - If the count of pages viewed hasn't already been iterated, the return value gets
         *        iterated from within the fn
         */
        getPagesViewedCount: function getPagesViewedCount () {

            // If sessionStorage is unavailable, early return null
            if (!flx.util.hasStorageCapability('sessionStorage')) {
                return null;
            }

            return (Analytics.state.isPgCountIterated)
                ? Analytics.fetchPageViewsFromStorage()
                : Analytics.fetchPageViewsFromStorage() + 1;

        },

        /*---------------[Time-Stamp Tracking Methods]---------------*/

        /**
         * Fetches a stamp value from storage
         * @param  {String} stampKey - key of stamp to fetch
         * @return {String} stampValue - value of stamp retrieved from storage
         */
        getStamp: function getStamp (stampKey) {

            // If the stamps aren't cached or we don't have a valid stampKey, early return
            if (!Analytics.state.areStampsCached || flx.util.isEmpty(stampKey)) {
                return null;
            }

            // Grab the stamp from the local state object
            return Analytics.state.stamps[stampKey];

        },

        /**
         * Fetches and caches stamp values into the state object from localStorage
         *
         * NOTES:
         *      - If there is no cached firstStamp value, we set all three values (first, last and
         *        current to the current timestamp)
         *      - We use the the areStampsCached bool to know when we can safely fetch these vals
         *      - We cached the last visit value BEFORE updating it with the current stamp
         */
        fetchAndCacheStamps: function fetchAndCacheStamps () {

            // Cache the config for storage keys
            var storageType = 'localStorage';
            var stampKeys = config.accessKeys.localStorage.stamps;

            // Fetch the stamps if they exist
            var firstStamp = flx.util.fetchFromStorage(storageType, stampKeys.first, null);
            var lastStamp = flx.util.fetchFromStorage(storageType, stampKeys.last, null);
            var currentStamp = flx.util.stampNow();

            // If a first visit isn't cached in storage, set 'first' AND 'last' to current time
            if (flx.util.isEmpty(firstStamp)) {

                firstStamp = flx.util.cacheInStorage(storageType, stampKeys.first, currentStamp);
                lastStamp = currentStamp;

            }

            // Cache stamps into the state obj
            Analytics.state.stamps = {
                first: firstStamp,
                last: lastStamp || currentStamp,
                current: currentStamp
            };

            // Mark the stamps as cached
            Analytics.state.areStampsCached = true;

            // The previous value for last visit was already fetched and cached into the state
            // object. We can now update the stored entry so that the user's next visit or page
            // view reflects the very LATEST visit
            flx.util.cacheInStorage(storageType, stampKeys.last, currentStamp);

        }

    };

    Analytics.segmentIdentify();
    Analytics.iteratePagesViewed();
    Analytics.fetchAndCacheStamps();
})();


(function () {

    var segmentKey =  flx.util.getSegmentKey();

    // Create a queue, but don't obliterate an existing one!
    var analytics = window.analytics = window.analytics || [];

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) {
        return;
    }

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
        if (window.console && console.error) {
            console.error('Segment snippet included twice.');
        }
        return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'page',
        'once',
        'off',
        'on'
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
        return function () {
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            analytics.push(args);
            return analytics;
        };
    };

    // For each of our methods, generate a queueing stub.
    for (var i = 0; i < analytics.methods.length; i++) {
        var key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (key) {
        // Create an async script element based on your key.
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') +
            'cdn.segment.com/analytics.js/v1/' +
            key + '/analytics.min.js';

        // Insert our script next to the first script element.
        var first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
    };



    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '3.1.0';

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(segmentKey);

})();
