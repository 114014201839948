(function () {

    flx.cart = {

        // Configuration details
        config: {
            targets: {
                details: '.js-cartDetails',
                checkoutBtn: {
                    miniCart: '.miniCartCheckout',
                    cart: '.js-proceedToCheckoutFromCart'
                }
            },
            emptyDetails: {
                pagination: {
                    pgCount: null,
                    itemsPerPage: null
                }
            },
            text: {
                ok: 'Proceed to Checkout',
                notOk: 'Continue Shopping'
            },
            featureFlags: {},
            redirectUrl: ''
        },

        /**
         * Initialize cart details
         *
         * NOTES:
         *     - Sets flx.cart.details to content of empty input $(targets.details)
         *     - Logs a warning, if there any issues parsing
         */
        init: function init () {

            var targets = flx.cart.config.targets;

            flx.cart.config.redirectUrl = window.location.origin + '/checkout/new-order';

            try {
                var cartDetails = JSON.parse($(targets.details).text());
                flx.cart.setDetails(cartDetails);
            } catch (err) {
                console.log('Err parsing cartDetails initializing w/ empty obj');
                flx.cart.setDetails({});
            }

            flx.cart.setLimits();
            flx.cart.bindAlerts();
            flx.cart.showCartLimitAlert();
            flx.cart.showRemovedCartItemsAlert();
            flx.cart.updateCheckoutBtnStatus();

        },

        /**
         * Checks the status of a cart and sets the checkout btn text accordingly
         *
         * NOTES:
         *     - Grabs values 'ok' and 'notOk' from config
         *     - Changes btn text based on the return val of the calcLimit() method
         */
        updateCheckoutBtnStatus: function updateCheckoutBtnStatus () {

            var targets = flx.cart.config.targets;
            var cartLimitCode = flx.cart.calcLimit();

            if (cartLimitCode !== 'OK') {
                for (var key in targets.checkoutBtn) {
                    $(targets.checkoutBtn[key]).addClass('inactive');
                }
            } else {
                for (var key in targets.checkoutBtn) {
                    $(targets.checkoutBtn[key]).removeClass('inactive');
                }
            }

        },

        /**
         * Bind cart page notifications for use globally and on mini-cart
         *
         * NOTES:
         *     - Checks config value as to whether it will show the global msg on a given pg
         */
        bindAlerts: function bindAlerts () {

            // If cart fails to update, show notifications
            $(document).on('cartUpdateError', function fireCartErrMsg (e, msg, spinnerId) {

                // Hide spinner if passed with event object
                if (flx.util.isNotEmpty(spinnerId)) {
                    flx.spinner.hideById(spinnerId);
                }

                // scroll to top
                window.scroll({top: 0, left:0, behavior: "smooth"});

                flx.msg.showMsg('error', 'Cart Error: ' + msg, 'global');
                flx.msg.showMsg('error', 'Mini-cart Error: ' + msg, 'miniCart');
            });

            // If cartRetailValue is above or below cart limit, show notifications
            $(document).on('cartLimitAlert', function showCartLimitAlert (e, msg) {

                // If not enabled at page level, the global msg will NOT show
                if (flx.cart.limits.show) {
                    flx.msg.showMsg('alert', msg, 'global');
                }

                flx.msg.showMsg('alert', msg, 'miniCart');

            });

        },

        /**
         * Our standard item submission for cart. Returns nothing and redirects user to checkout upon completion.
         * Intended for use on product pages primarily.
         *
         * @param {Object} itemInfo - Product payload
         * @param {String?} spinnerId - Spinner
         */
        postItemToCart: function postItemToCart (itemInfo, spinnerId) {
            $.ajax({
                url: window.location.origin + '/cart/addItem',
                method: 'POST',
                dataType: 'json',
                data: itemInfo
            })
                .done(function (cartData) {
                    flx.analytics.sendSegmentAnalytics(cartData.analyticsTrack, 'Product Added');

                    if (
                        typeof window.upsellit === "undefined" ||
                        typeof window.upsellit.preventAddToCartRedirect === "undefined"
                    ) {
                        window.location.href = flx.cart.config.redirectUrl;
                        return;
                    }
                    $(document).trigger('cartUpdate', [spinnerId, 'cart_add']);
                })
                .fail(function (xhr, errDesc, err) {
                    let failMsg = 'Unable to add to cart';

                    if (xhr.status >= 500 || xhr.status <= 0) {
                        failMsg = 'Network error: Please refresh the page and try again.';
                    }

                    $(document).trigger('cartUpdateError', [failMsg, spinnerId]);
                    flx.addingToCart = false;
                });
        },

        /**
         * Return curried addToCart fn
         * @param {String | Null} qtyBox - jQuery selector for qtyBox or null
         */
        addToCart: function addToCart (qtyBox) {

            // Get qty of items to add, defaults to 1
            var qty = 1;
            if (qtyBox !== null && $(qtyBox).length > 0) {
                qty = $(qtyBox).val();

                // Need this to track changes to qty input val
                $(qtyBox).on('change.skuPageAddToCart', function changeQtyOnSkuPage () {
                    qty = $(qtyBox).val();
                });

            }

            /**
             * Add item to shopping cart
             * @param {Object} e - event object
             */
            return function addToCartCurried (e) {
                e.preventDefault();
                if (flx.addingToCart) return;
                flx.addingToCart = true;

                // Show spinner to indicate to user the item is being added
                const spinnerId = flx.spinner.show();

                // Cache inv id
                const invId = $(this).attr('inv-id');

                const productId = $(this).attr('product-id');
                const productPrice = $(this).attr('product-price');
                const productSku = $(this).attr('sku');

                // Build the info object
                const itemAddInfo = {
                    cartId: $(this).attr('cart-id'),
                    invId: invId,
                    cartItem: {
                        qty: qty,
                        inventoryId: invId,
                        productId: productId,
                        productPrice: productPrice,
                        product_sku: productSku,
                    },
                    fullUrl: document.location.href
                };

                flx.cart.postItemToCart(itemAddInfo, spinnerId);
            };
        },

        /**
         * Process the cart object and map it for db update
         * @param  {Object} _cartDetails - cart details (preferably a copy of it)
         * @param  {String} slug - Product Slug (for item removal)
         * @return {Object} mappedCart - mapped object for db update
         */
        processForUpdate: function processForUpdate (_cartDetails, slug) {

            var cartItemsForUpdate = [];

            // Remove targeted productSlug from _cartDetails
            if (typeof slug !== 'undefined') {
                _cartDetails.items.splice(flx.cart.getItemIndex(slug, 'slug', _cartDetails), 1);
            }

            // Count and subtotal
            flx.cart.recalcCartTotals(_cartDetails);

            // Map items for db update
            for (var i = 0; i < _cartDetails.items.length; i++) {
                cartItemsForUpdate.push({
                    sku: _cartDetails.items[i].sku,
                    qty: parseInt(_cartDetails.items[i].quantity)
                });
            }

            // Map cart for db update
            return {
                items: cartItemsForUpdate,
                itemsCount: _cartDetails.itemsCount || 0,
                itemsQty: _cartDetails.itemsQty || 0,
                subTotal: _cartDetails.subTotal || 0,
                product_sku: cartItemsForUpdate.sku,
            };

        },

        /**
         * Submits a collection of "add to cart" requests based on the number of productIds provided. In the case of
         * failure, it will attempt to recover the experience by sending the user to the item's product page.
         *
         * This function should never be called on a product page if the item is out of stock.
         *
         * If singleInventoryId is provided, it'll add that specific inventory to cart and ignore the others.
         *
         * @param  {String | [{productId: String, inventoryId?: String, qty?: Number}]} productIdOrArray - Singular
         * productId or array of product and inventory ids to be looked up and added  by qty.
         * @param {String?} singleInventoryId - Optional product inventory id to add a specific inventory item directly.
         * @param {Number?} qty - Amount to add
         */
        multiAddToCart: function multiAddToCart (productIdOrArray, singleInventoryId, qty = 1) {
            // Show spinner to indicate to user the item is being added
            const spinnerId = flx.spinner.show();

            const addItemRequests = [];
            const cartData = flx.cart.details;
            /**
             * In certain cases, when a user navigates from the checkout page to the PDP and attempts to add a new item,
             * the cart object may not have been initialized.
             * This can result in a critical failure of the application without the possibility of recovery.
             * This code is a fixup for that case
             * */
            if (!cartData) {
                setTimeout(() => multiAddToCart(productIdOrArray, singleInventoryId, qty), 2000);
                return;
            }
            const cartId = cartData.id;
            const productsRequested = Array.isArray(productIdOrArray)
                ? productIdOrArray
                : [
                      {
                          productId: productIdOrArray,
                          qty: Number(qty)
                      }
                  ];
            let isMultiRequest = productsRequested.length > 1;
            const params = {
                products: productsRequested,
                singleInventoryId: singleInventoryId
            };
            const warnAndRecover = function warnAndRecover(slug, errMsg, error) {
                const recoveryUrl = window.location.origin + '/product/' + slug;

                console.warn(errMsg, error);
                console.info(`Redirecting customer to: ${recoveryUrl}`);

                return (window.location.href = recoveryUrl);
            };
            if (flx.util.getDeviceType() === 'mobile') {
                flx.cart.clearCartItemsCountForMobile();
            }

            // Gets inventory data and pre-formatted cart item payload for us.
            $.ajax({
                url: window.location.origin + '/cart/multiAdd',
                method: 'GET',
                dataType: 'json',
                data: params
            })
                .done(function (cartPayloads) {
                    // Update isMultiRequest if a bundle item was requested and returned multiple payloads
                    if (cartPayloads.length > 1 && !isMultiRequest) {
                        isMultiRequest = true;
                    }

                    // Form our collection of requests to be processed.
                    $.each(cartPayloads, function (index, currentItem) {
                        addItemRequests.push(
                            $.ajax({
                                url: window.location.origin + '/cart/addItem',
                                method: 'POST',
                                dataType: 'json',
                                data: {
                                    cartId: cartId,
                                    invId: currentItem.inventoryId,
                                    cartItem: currentItem.cartPayload,
                                    qty: currentItem.qty,
                                    fullUrl: document.location.href // Current page URL for analytics reporting
                                }
                            })
                                .done(function (cartData) {
                                    // Send analytics data
                                    flx.analytics.sendSegmentAnalytics(cartData.analyticsTrack, 'Product Added');
                                })
                                .fail(function (error) {
                                    // If we failed to process an item addition on backend for whatever reason, attempt
                                    // to recover by sending the customer directly to the item's product page.
                                    return warnAndRecover(
                                        currentItem.slug,
                                        'Failed to add product to cart. Attempting recovery.',
                                        error
                                    );
                                })
                        );
                    });

                    // Run and handle previously built requests
                    $.when.apply($, addItemRequests)
                        .then(function () {
                            /**
                             * jQuery returns three arguments if only a single request was handled. If multiple requests
                             * were processed, each entry value in the arguments object will be an array type. The only
                             * reliable way to check if we had multiple responses is to check the value type of the first
                             * key in the arguments object.
                             */
                            var isMultiResponse = Array.isArray(arguments[0]);
                            var successfulCartAdditions = isMultiResponse ? arguments : [arguments[0]];

                            // Log successful additions.
                            $.each(successfulCartAdditions, function(index, responseData){
                                console.info(
                                    'Add multiple items result '
                                    + (index + 1) + ' of ' + successfulCartAdditions.length + ':',
                                    responseData
                                );
                            });

                            // Send notice to customer and log one or more items failed to be added.
                            if (isMultiRequest && !isMultiResponse) {
                                console.warn('Failed to add one or more items to cart.');
                                $(document).trigger(
                                    'cartUpdateError',
                                    ['Unable to add one or more requested items to your cart.', spinnerId]
                                );
                            }

                            // Trigger cart update and redirect to checkout.
                            //$(document).trigger('cartUpdate', [spinnerId, 'cart_add']);
                            window.location.href = flx.cart.config.redirectUrl;
                        });
                })
                .fail(function (error) {
                    flx.addingToCart = false;
                    // Manage rejection from handler
                    const response = error.responseJSON;
                    const recoverySlug = response && response.recoverySlug;
                    const consoleMsg = response && response.msg;
                    const customerMsg = 'Failed to add item(s) to cart. Please refresh the page and try again.';

                    if (recoverySlug) {
                        return warnAndRecover(
                            recoverySlug,
                            consoleMsg || 'Failed to process item for cart. Attempting recovery.'
                        );
                    }

                    // Unrecoverable failure. Notify customer and remove spinner.
                    console.error('Failed to process item for cart.', error);
                    $(document).trigger('cartUpdateError', [customerMsg, spinnerId]);
                });
        },
        clearCartItemsCountForMobile: function clearCartItemsCountForMobile() {
            window.sessionStorage.removeItem('cartCount');
        },
        getCartItemsCountForMobile: function getCartItemsCountForMobile() {
            var cartItemControl = $('.miniCartQty');
            cartItemControl.hide();
            // try
            // {
            //     var cartItemControl = $('.miniCartQty');
            //     cartItemControl.hide();
            //     var cartCount = window.sessionStorage.getItem('cartCount');
            //     if(cartCount){
            //         if(parseInt(cartCount) > 0){
            //             cartItemControl.text(cartCount);
            //             cartItemControl.css('display', 'inline-block');
            //             cartItemControl.show();
            //         }
            //         return;
            //     }

            //     var cartData = flx.cart.details;
            //     var cartId = cartData.id;
            //     var params = {
            //         cartId: cartId,
            //     };
            //     $.ajax({
            //         url: window.location.origin + '/cart/getItems',
            //         method: 'GET',
            //         dataType: 'json',
            //         data: params
            //     })
            //     .done(function(qty){
            //         if(parseInt(qty) > 0){
            //             cartItemControl.text(qty);
            //             cartItemControl.css('display', 'inline-block');
            //             cartItemControl.show();
            //         }
            //         window.sessionStorage.setItem('cartCount', qty);
            //     })
            //     .fail(function (xhr, errDesc, err) {
            //         console.error('error in getCartItemsCountForMobile', err);
            //         cartItemControl.hide();
            //     });
            // }
            // catch(err){
            //     console.error('error in getCartItemsCountForMobile', err);
            // }
        },
        removeFromCart: function removeFromCart (cartItemId) {
            return $.ajax({
                url: window.location.origin + '/cart/removeItem',
                method: 'POST',
                dataType: 'json',
                data: {
                    cartItemId: cartItemId
                }
            });
        },
        removeAllCartItems: function removeAllCartItems (cartId) {
            return $.ajax({
                url: window.location.origin + '/cart/removeAllItems',
                method: 'POST',
                dataType: 'json',
                data: {
                    cartId: cartId
                }
            });
        },
        addRemoveFlexAdvantage: function addRemoveFlexAdvantage (status) {
            return $.ajax({
                url: window.location.origin + '/flexAdvantage/addRemove',
                method: 'POST',
                dataType: 'json',
                data: {
                    faInCart: status
                }
            });
        },

        /**
         * API call to /updateItem for cart
         * @param {Object} cartItemId - id of cart item
         * @param {Object} cartItemQty - qty to update item to
         * @return {Promise} updateItemQty - promise of cart qty update
         */
        updateItemQty: function updateItemQty (cartItemId, cartItemQty) {
            return $.ajax({
                url: window.location.origin + '/cart/updateItem',
                method: 'POST',
                dataType: 'json',
                data: {
                    cartItem: {
                        cartItemId: cartItemId,
                        qty: cartItemQty
                    }
                }
            });
        },

        /**
         * Get index of cart item by its SKU id
         * @param  {String} value - Product value to fetch index for in cart
         * @param  {String} name - Product name to fetch index for in cart
         * @param  {Object} cart - cart object
         */
        getItemIndex: function getItemIndex (value, name, cart) {

            // Init obj
            var index = {
                lineItemCount: 0,
                indexOfItem: null
            };

            // Iterate through list of items and populate obj data
            for (var i = 0; i < cart.items.length; i++) {
                if (cart.items[i][name] === value) {
                    index.lineItemCount++;
                    index.indexOfItem = i;
                }
            }

            // Show warning if more than one line items for a single Product
            if (index.lineItemCount > 1) {
                console.log('Warning: There is more than 1 matching Product ' + name + ' in cart for Product ' +
                    name + ' : ' + value);
            }

            return index.indexOfItem;

        },

        /**
         * Recalculates the items count and subtotal for given cart object
         * @param  {Object} cart - cart object
         *
         * NOTE:
         * This fn alters the passed-in obj, so consider passing in a copy
         * if you don't want to alter the original object.
         */
        recalcCartTotals: function recalcCartTotals (cart) {

            // THIS DOES NOT INCLUDE SHIPPING. DO NOT USE WHEN SHIPPING COSTS ARE EXPECTED
            // This function is used globally for mini cart calculations which does not currently always have access
            // to shipping information.

            cart.itemTotal = 0;
            cart.subTotal = 0;
            cart.retailValue = 0;
            for (var i = 0; i < cart.items.length; i++) {
                var qty = cart.items[i].quantity || 0;
                cart.itemTotal += qty;
                cart.retailValue += Number(cart.items[i].fullPrice * qty);
                cart.subTotal += Number(cart.items[i].price) * qty;
            }
        },

        /**
         * Sets cart details to passed in dataSource
         * @param  {Object} dataSource - cart details obj
         *
         * NOTES:
         *     - If no dataSource is passed, keeps val intact
         *     - If cartDetails haven't been set, inits to empty object
         */
        setDetails: function setDetails (dataSource) {
            if (flx.util.isEmpty(dataSource)) {
                console.log('Warning: Data source for cart details is empty. '
                    + 'Initializing the value');

                // If not already set, init cart details to flx.cart.config.emptyDetails
                flx.cart.details = flx.util.isNotEmpty(flx.cart.details)
                    ? flx.cart.details
                    : flx.util.copyByJson(flx.cart.config.emptyDetails);

            } else {
                flx.cart.details = dataSource;
            }

            // Update the body element's data to latest version as well
            $(flx.cart.config.targets.details).text(JSON.stringify(flx.cart.details));
        },

        /**
         * Set cart limits by pulling values from DOM inputs
         *
         * NOTES:
         *     - `limits.show` comes from pageView.showBigCartLimitMsg
         *     - `limits.show` tells us whether to show the big msg or not on a given pg
         */
        setLimits: function setLimits (limits) {
            flx.cart.limits = limits || flx.cart.limits || {
                show: $('.js-showBigCartLimitMsg').val(),
                min: parseInt($('.js-cartLimitMin').val()),
                max: parseInt($('.js-cartLimitMax').val()),
                minPrice: parseInt($('.js-cartLimitMinPrice').val()),
            };
        },

        /**
         * Checks contents of cart to determine status code
         * @return {String} cartLimitCode - status code for cart
         *
         * NOTES:
         *     - If no items, will be 'EMPTY'
         *     - If not enough in cart, will be 'UNDER-LIMIT'
         *     - If too much in cart, will be 'ABOVE-LIMIT'
         */
        calcLimit: function calcLimit () {

            var cartLimitCode;

            if (flx.cart.details.items.length < 1) {
                cartLimitCode = 'EMPTY';
            } else if (flx.cart.details.retailValue < flx.cart.limits.min) {
                cartLimitCode = 'UNDER-LIMIT';
            } else if (flx.cart.details.retailValue > flx.cart.limits.max) {
                cartLimitCode = 'ABOVE-LIMIT';
            } else {
                cartLimitCode = 'OK';
            }

            return cartLimitCode;

        },

        /**
         * Event handler for checkout btn
         * @param  {Event} e - triggering event object
         *
         * NOTES:
         *     - Fires an AJAX call to our checkout route
         *     - Doesn't pass any data directly (backend fetches cart data via mware)
         *     - If cart is empty or under/over limit, displays an err msg
         *
         *     ------------ ON RESOLUTION ----------------
         *     - On success, will redirect to FPAY
         *     - Otherwise, it will show the user an err msg
         */
        toCheckout: function toCheckout (e) {

            e.preventDefault();
            window.location.href = e.target.href ? e.target.href : flx.cart.config.redirectUrl;

        },

        /**
         * Displays an err when user attempts to checkout w/ an empty cart
         *
         * NOTES:
         *     - Gets called inside of toCheckout()
         *     - Only fires if flx.cart.details.items is empty
         *     - Binds a one-time click handler that hides the err msg
         */
        showEmptyCartError: function showEmptyCartError (interval) {

            var emptyCartErrMsg = 'Cart Error: Must have items in your cart to checkout';

            // Mini-cart notification
            flx.msg.showMsg('error', emptyCartErrMsg, 'miniCart');
            flx.msg.showMsg('error', emptyCartErrMsg, 'global');

            // Bind hide msg to document click (removes handler after single click)
            // NOTE: we give a short timeout here to avoid accidental double-clicks
            setTimeout(function () {
                $(document).one('click.emptyCartErr', function (e) {
                    flx.msg.hideMsg([emptyCartErrMsg]);
                });
            }, interval || 250);

        },

        /**
         * Triggers the out of stock error if an item was removed from the cart
         */
        showRemovedCartItemsAlert: function showRemovedCartItemsAlert () {

            var $emptyItemsInput = $('.js-itemsOutOfStock');

            if ($emptyItemsInput.length > 0) {
                flx.cart.showIneligibleItemsError($emptyItemsInput.val());
            }

        },

        /**
         * Displays an err when an item has gone out of stock and has been deleted from the cart
         */
        showIneligibleItemsError: function showIneligibleItemsError (removedItems, interval) {

            var itemNames = '';
            removedItems = flx.util.safeParse(removedItems);

            for (var i = 0; i < removedItems.length; i++) {
                itemNames = itemNames.concat(removedItems[i]);

                if (i !== removedItems.length - 1) {
                    itemNames = itemNames.concat('<br /><br />-');
                }
            }

            var ineligibleItemsErr = 'We are sorry. These item(s) cannot be shipped to your '
                + 'area or have recently gone out-of-stock:<br /><br />- '
                + itemNames;

            flx.msg.showMsg('error', ineligibleItemsErr, 'global');
            flx.msg.showMsg('error', ineligibleItemsErr, 'miniCart');

            // Bind hide msg to document click (removes handler after single click)
            // NOTE: we give a short timeout here to avoid accidental double-clicks
            setTimeout(function () {
                $(document).one('click.ineligibleItemsErr', function (e) {
                    flx.msg.hideMsg();
                });
            }, interval || 500);

        },

        /**
         * Determines whether or not to display the cart limit alert
         *
         * NOTES:
         *     - Gets cartLimitCode to determine what messaging to show
         *     - If cartLimitCode === ('EMPTY' || 'OK'), will hide all messaging
         */
        showCartLimitAlert: function showCartLimitAlert () {

            $(function () {
                const cartLimitExceeded = $('#cartLimitExceeded');
                if (cartLimitExceeded && cartLimitExceeded.length) {
                    const msg =
                        'Sorry, your cart is currently full. Please proceed to checkout' +
                        ' to complete your order before adding additional items.';
                    flx.msg.showMsg('info', msg, 'global');
                }
            });

            // Init vars
            const cartLimitCode = flx.cart.calcLimit();
            const alertMinMsg = 'Cart must have more than $' + flx.cart.limits.min / 100;
            const alertMaxMsg = 'Cart must have less than $' + flx.cart.limits.max / 100;

            // Hide alert if no items in cart, show if flx.cart.details.retailValue is below Min
            // or above max... Otherwise, hide the alert
            if (cartLimitCode === 'EMPTY') {
                flx.msg.hideMsg([alertMinMsg, alertMaxMsg]);
            } else if (cartLimitCode === 'UNDER-LIMIT') {
                $(document).trigger('cartLimitAlert', [alertMinMsg]);
            } else if (cartLimitCode === 'ABOVE-LIMIT') {
                $(document).trigger('cartLimitAlert', [alertMaxMsg]);
            } else {
                flx.msg.hideMsg([alertMinMsg, alertMaxMsg]);
            }

        }

    };

    $(document).on(flx.util.events.REMOTE_FLAGS_LOADED,
        function ($event, featureFlagsBackEndPlugin) {
            flx.cart.config.featureFlags = featureFlagsBackEndPlugin.flags;

            if (!featureFlagsBackEndPlugin.flags.fpcFmweb) {
                flx.cart.init();
                flx.miniCart.init();
            }
        });

})();
