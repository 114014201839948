(function () {
    /**
    * Local vars
    */
    var search = location.search;

    if (document.location.pathname.indexOf('search/') > -1) {
        search = document.location.pathname.replace('search/', '?q=');
    }

    var customerData = flx.util.safeParse($('input[name="customerData"]').val());
    if (customerData.email) {
        analytics.identify({userId: customerData.kongCustomerId,  email:customerData.email});
    }
})();
