(function () {
    /**
     * Listen on when the flags have been loaded to do the fpc
     */
    $(document).on(flx.util.events.REMOTE_FLAGS_LOADED, function (
        $event,
        featureFlagsBackEndPlugin,
        flagManifest
    ) {
        /*----------[Module Configuration]----------*/
        featureFlagsBackEndPlugin('fpcFmweb', true, function () {
            var config = {
                targets: {
                    miniCartAjaxPlaceholder: '.js-miniCartAjaxPlaceholder',
                },
            };

            /*----------[Module Object]----------*/

            var fpcModule = (flx.fpcModule = {
                init: function init() {
                    // No punch thru
                    // TODO refactor this code into a module
                    if (!flagManifest.fpcPunchData) {
                        $('.logOut').on('click', function () {
                            $.removeCookie('punchDataOff_cartItemsTotal');
                            $.removeCookie('punchDataOff_firstName');
                        });
                        var miniCartTotal = $.cookie(
                            'punchDataOff_cartItemsTotal'
                        );
                        miniCartTotal = isNaN(miniCartTotal)
                            ? 0
                            : miniCartTotal;

                        var firstName = $.cookie('punchDataOff_firstName');
                        firstName =
                            firstName && firstName.length ? firstName : null;

                        $('.miniCartQty').text(miniCartTotal);

                        if (firstName) {
                            $('.userName').text('Hello, ' + firstName + '!');
                            $('.myAcctBtn').addClass('hasAcct');
                            $('.hasAcctMenu').removeClass('hidden');
                        } else {
                            $('.myAcctBtn').addClass('noAcct');
                            $('.noAcctMenu').removeClass('hidden');
                        }
                        return;
                    }

                    // Fetch whatever flyouts / partials we need
                    return fpcModule.ajaxLoad().done().fail();
                },

                /*----------[Loader Methods]----------*/

                ajaxLoad: function ajaxLoad() {
                    // Init the list
                    var fpcActionsList = [fpcModule.loadDynamicData()];

                    // Minicart is available only on desktop
                    if (flx.util.getDeviceType() === 'desktop' && !flx.util.getIsMiniCartDisabled()) {
                        fpcActionsList.push(fpcModule.loadMiniCart());
                    }

                    return $.when
                        .apply($, fpcActionsList)
                        .done(function () {
                            flx.cart.init();
                            // Minicart needs to be initialize on desktop only
                            if (flx.util.getDeviceType() === 'desktop') {
                                flx.miniCart.init();
                            }
                            if (flx.util.getDeviceType() === 'mobile') {
                                flx.cart.getCartItemsCountForMobile();
                            }

                            $(document).trigger(
                                flx.util.events.FPC_LOAD_ANALYTICS,
                                [analytics[0]]
                            );
                        })
                        .fail(fpcModule.handleFpcGenericError);
                },
                /**
                 * Load the minicart content Ajax
                 */
                loadMiniCart: function loadMiniCart() {
                    // Attach spinner to minicart element in header while loading
                    var spinnerId = flx.spinner.show({
                        spinnerGlobal: false,
                        target: config.targets.miniCartAjaxPlaceholder,
                    });

                    return $.when(fpcModule.buildMiniCartCall())
                        .done(function (miniCartHtml) {
                            flx.spinner.hideById(spinnerId);
                            $(
                                config.targets.miniCartAjaxPlaceholder
                            ).replaceWith(miniCartHtml);
                        })
                        .fail(function (err) {
                            fpcModule.handleMiniCartErr(err);
                        });
                },

                /**
                 * Loads information for tracking analytics and
                 * user content
                 * @returns {*}
                 */
                loadDynamicData: function () {
                    return $.when(
                        fpcModule.buildTrackDataCall(),
                        fpcModule.buildFpcPunchHols()
                    ).fail(fpcModule.handleFpcGenericError);
                },

                /*----------[AJAX Call Builders]----------*/

                buildMiniCartCall: function buildMiniCartCall() {
                    return $.ajax({
                        url: '/partials/mini-cart',
                        method: 'GET',
                        dataType: 'html',
                    });
                },

                buildTrackDataCall: function buildMiniCartCall() {
                    return $.ajax({
                        url: '/fpc/api/track-data',
                        method: 'GET',
                        dataType: 'json',
                        success: function (data) {
                            window.dataLayer && window.dataLayer.push({
                                spending_limit: data.spending_limit,
                            });
                        },
                    });
                },

                buildFpcPunchHols: function buildFpcPunchHols() {
                    return $.ajax({
                        url: '/fpc/api/punch-data',
                        method: 'GET',
                        dataType: 'json',
                    }).done(loadFpcPunchHoles);
                },

                /*----------[Err Messaging]----------*/

                handleFpcGenericError: function handleMyAcctErr(
                    jqXHR,
                    status,
                    errMsg
                ) {
                    if (!errMsg || errMsg.length) {
                        return;
                    }

                    console.error(errMsg);

                    flx.msg.showMsg(
                        'error',
                        'We could not fetch some data. Please retry...',
                        'global'
                    );
                },

                handleMiniCartErr: function handleMiniCartErr(
                    jqXHR,
                    status,
                    errMsg
                ) {
                    if (!errMsg || errMsg.length) {
                        return;
                    }

                    console.error(errMsg);

                    flx.msg.showMsg(
                        'error',
                        'We could not fetch data for your cart. Please refresh the page...',
                        'global'
                    );
                },
            });

            // Init the module
            return fpcModule.init();
        });
    });

    /**
     * Use action based loading of the dynamic content
     * Currently supported actions
     * 1. class - adds class to a DOM element
     * 2. replace - replaces a DOM element
     * @param data
     */
    function loadFpcPunchHoles(data) {
        const pluginDiv = document.createElement('div');

        pluginDiv.setAttribute('id', 'payment-plugin-container');
        document.body.appendChild(pluginDiv);

        data.actions.forEach(function (action) {
            switch (action.type) {
                case 'class':
                    $(action.meta.selector).addClass(action.meta.value);
                    break;
                case 'replace':
                    $(action.meta.selector).replaceWith(action.meta.value);
                    break;
            }
        });
    }
})();
