(function () {

    var flags = {};
    var initialized = false;

    // Initialize the plugin only on event. Initialization is singleton
    $(document).on(flx.util.events.LOAD_REMOTE_FLAGS, function ($events, $flags) {

        var featureFlagsBackEndPlugin = function (flag, equality, executeCallback) {
            if (flags[flag] && flags[flag] === equality) {
                return executeCallback(flags[flag]);
            }
        };

        var flagManifest = $flags;

        if (!initialized) {
            initialized = !initialized;
            flags = $flags;
        }

        // Convenient flag data
        featureFlagsBackEndPlugin.flags = flx.util.copyByJson(flags);

        // Notify all listeners that remote flags have been loaded into the plugin
        // and can be interacted with
        $(document).trigger(flx.util.events.REMOTE_FLAGS_LOADED, [featureFlagsBackEndPlugin, flagManifest]);
    });

})();
