(function () {

    /*----------[Module Configuration]----------*/

    var config = {
        deviceType: flx.util.getDeviceType(),
        environment: flx.util.getEnv(),
        reflektionEnabled: flx.util.getRfkStatus(),
        rfkApiEnabled: false,
        searchMinChar: 2,
        keyCodes: {
            upArrow: 38,
            downArrow: 40,
            escape: 27,
            enter: 13
        },
        activeClass: 'predSearchActive',
        targets: {
            predSearchContainer: '.predSearchContainer',
            predSearchWrap: '.globalSearchBar',
            predSearchInput: '.js-predSearchInput',
            predSearchTerm: '.js-predSearchTerm',
            predSearchGo: '.js-predSearchGo'
        }
    };

    /*----------[Module Object]----------*/

    var PredictiveSearch = flx.predSearch = {

        // ________ Properties ________ //

        state: {
            resultsNum: 0,
            selectedResultIndex: -1,
            modalOpen: false,
            lastInput: null,
        },

        // ________ Methods ________ //

        init: function init () {

            PredictiveSearch.bindInput();

        },

        bindInput: function bindInput () {

            $(config.targets.predSearchInput).on(
                'input', flx.util.debounce(PredictiveSearch.handleInput, 750)
            );

            if (config.rfkApiEnabled) {
                $(config.targets.predSearchInput).on('focus', PredictiveSearch.handleInput);
            }

        },

        // Bind keycontrol for closing modal after modal is open
        bindCloseDropdown: function bindCloseDropdown () {

            // Escape keypress to close
            $(document).on(
                'keydown.closePredSearch', function (e) {
                    if (e.which == config.keyCodes.escape) {
                        PredictiveSearch.hideDropdown();
                    }
                }
            );

            // Click on document to close modal
            $(document).on(
                'click.closePredSearch', function () {
                    PredictiveSearch.hideDropdown();
                }
            );

            // Prevent closing modal by clicking inside predSearch container
            $(config.targets.predSearchContainer).on(
                'click', function (e) {
                    e.stopPropagation();
                }
            );

            // Prevent closing modal by clicking inside search bar input
            $(config.targets.predSearchWrap).on(
                'click', function (e) {
                    e.stopPropagation();
                }
            );

        },

        // Unbind handlers for closing modal after closing it
        unbindCloseDropdown: function unbindCloseDropdown () {
            $(document).off('keydown.closePredSearch');
            $(document).off('click.closePredSearch');
        },

        // Add predSearch results to DOM
        bindArrows: function bindArrows () {
            $(document).on(
                'keydown.arrowNavigation', function (e) {
                    if (e.which == config.keyCodes.downArrow) {
                        PredictiveSearch.selectNextResult();
                    } else if (e.which == config.keyCodes.upArrow) {
                        PredictiveSearch.selectPrevResult();
                    }
                }
            );

        },

        // Unbind arrow navigation control after modal is closed
        unbindArrows: function unbindArrows () {
            $(document).off('keydown.arrowNavigation');
        },

        // After a reulst term is selected, bind enter keypress handler
        // Get URL from selected result and redirect
        bindSuggestChoice: function bindSuggestChoice () {
            PredictiveSearch.unbindSuggestChoice();

            // Prevent default enter keypress when a result is selected
            $(document).on(
                'keydown.defaultSubmit', function (e) {
                    if (e.which == config.keyCodes.enter) {
                        e.preventDefault();
                    }
                }
            );

            // Bind enter keypress to get current result url and redirect
            $(document).on(
                'keydown.selectTerm', function (e) {
                    if (e.which == config.keyCodes.enter) {
                        var currIndex = PredictiveSearch.state.selectedResultIndex;
                        var targetLink = $(config.targets.predSearchTerm)
                            .eq(currIndex).closest('.predProductLink').attr('href');
                        var url = window.location.origin + targetLink;
                        window.location = url;
                    }
                }
            );

            // Remove current selected result and reset index after mouse hover on results
            $(config.targets.predSearchTerm).on(
                'mouseenter.resetSelectedIndex', function () {
                    var currIndex = PredictiveSearch.state.selectedResultIndex;
                    if (currIndex !== -1) {
                        $(config.targets.predSearchTerm).eq(currIndex).removeClass('active');
                        PredictiveSearch.state.selectedResultIndex = -1;
                    }
                }
            );

        },

        // Unbind events for preventing default enter submit and search redirect
        unbindSuggestChoice: function unbindSuggestChoice () {
            $(document).off('keydown.defaultSubmit');
            $(document).off('keydown.selectTerm');
            $(config.targets.predSearchTerm).off('mouseenter.resetSelectedIndex');
        },

        handleInput: function handleInput () {

            var $inputElem = $(this);
            var searchTerm = PredictiveSearch.captureSearchTerm($inputElem);

            if (config.rfkApiEnabled && !PredictiveSearch.isNewInput(searchTerm)) {
                // RFK-API implementation uses the focus event to allow rendering of results when the input is
                // still empty. There is a known "bug" in chrome where if the input is focused and the user
                // changes windows, the focus event is fired again when returning to the window/tab.
                // This causes multiple rfk tracking events and search requests to be sent.
                return;
            }

            PredictiveSearch.state.lastInput = searchTerm;
            PredictiveSearch.fetchResults(searchTerm);

        },

        captureSearchTerm: function captureSearchTerm ($inputElem) {

            var searchTerm = '';

            if (flx.util.isEmpty($inputElem)) {
                console.log('captureSearchTerm warning: $inputElem was not passed in');
                return searchTerm;
            }

            searchTerm = $inputElem.val();

            return searchTerm;

        },

        isNewInput: function isNewInput (searchTerm) {
            return searchTerm !== PredictiveSearch.state.lastInput;
        },

        selectNextResult: function selectNextResult () {
            var currIndex = PredictiveSearch.state.selectedResultIndex;
            PredictiveSearch.bindSuggestChoice();

            // Set html class and styling accordingly
            // Increment the selectedResultIndex
            if (currIndex !== -1) {
                $(config.targets.predSearchTerm).eq(currIndex).removeClass('active');

                if (currIndex !== PredictiveSearch.state.resultsNum - 1) {
                    currIndex++;
                } else {
                    currIndex = 0;
                }
            } else {
                currIndex++;
            }

            $(config.targets.predSearchTerm).eq(currIndex).addClass('active');
            PredictiveSearch.state.selectedResultIndex = currIndex;
        },

        selectPrevResult: function selectPrevResult () {
            var currIndex = PredictiveSearch.state.selectedResultIndex;
            PredictiveSearch.bindSuggestChoice();

            // Set html class and styling accordingly
            // De-crement the selectedResultIndex
            if (currIndex !== -1) {
                $(config.targets.predSearchTerm).eq(currIndex).removeClass('active');
                if (currIndex !== 0) {
                    currIndex--;
                } else {
                    currIndex = PredictiveSearch.state.resultsNum - 1;
                }
            } else {
                currIndex = PredictiveSearch.state.resultsNum - 1;
            }

            $(config.targets.predSearchTerm).eq(currIndex).addClass('active');
            PredictiveSearch.state.selectedResultIndex = currIndex;

        },

        // Reset results number and current selected result index
        // Loop through returned products and set value to resultsNum
        getResultIndex: function getResultIndex () {

            PredictiveSearch.initResultIndex();
            PredictiveSearch.resetResultIndex();

            $(config.targets.predSearchTerm).each(function () {
                PredictiveSearch.state.resultsNum++;
            });
        },

        // Initialize result number to 0
        initResultIndex: function initResultIndex () {

            PredictiveSearch.state.resultsNum = 0;

        },

        // Reset current selected result index to -1
        resetResultIndex: function resetResultIndex () {

            PredictiveSearch.state.selectedResultIndex = -1;

        },

        fetchResults: function fetchResults (searchTerm) {

            var resultsCall = PredictiveSearch.buildResultsCall(searchTerm);

            if (flx.util.isEmpty(resultsCall)) {
                return null;
            }

            resultsCall
                .done(PredictiveSearch.resultsDone)
                .fail(PredictiveSearch.resultsFail);

        },

        fetchCategoryResults: function fetchCategoryResults (searchTerm) {
            var resultsCall = PredictiveSearch.buildResultsCall(searchTerm);
            if (flx.util.isEmpty(resultsCall)) {
                return null;
            }
            resultsCall
                .done(PredictiveSearch.categoryResultsDone)
                .fail(PredictiveSearch.resultsFail);
        },

        categoryResultsDone: function categoryResultsDone(predResults) {
            // if 0 result returned from neither search suggestions or product suggestions
            // close modal and unbind handlers
            if (!predResults.isRfkApiResponse &&
                flx.util.isEmpty(predResults.rawResults.suggestions) &&
                flx.util.isEmpty(predResults.rawResults.suggestProduct)
            ) {
                PredictiveSearch.hideDropdown();
                return null;
            }
            var tempDiv = document.createElement('div');
            tempDiv.innerHTML = predResults.html;

            var newProductResults = tempDiv.querySelector('.product-results');
            var existingProductResults = document.querySelector('.product-results');

            if (newProductResults && existingProductResults) {
                existingProductResults.innerHTML = newProductResults.innerHTML;
            }

            if (!PredictiveSearch.state.modalOpen) {
                PredictiveSearch.showDropdown();
                PredictiveSearch.bindCloseDropdown();
                PredictiveSearch.bindArrows();
            }
        },

        seachBarEvent: function seachBarEvent (payload) {
            $.ajax({
                url: window.location.origin + '/rfk-event',
                data: payload,
                method: 'POST',
                type: 'json'
            })
            .fail(function (err) {
                console.error('Failed to send event to RFK', err);
            });
            return
        },

        resultsDone: function resultsDone (predResults) {

            // if 0 result returned from neither search suggestions or product suggestions
            // close modal and unbind handlers
            if (!predResults.isRfkApiResponse &&
                flx.util.isEmpty(predResults.rawResults.suggestions) &&
                flx.util.isEmpty(predResults.rawResults.suggestProduct)
            ) {
                PredictiveSearch.hideDropdown();
                return null;
            }

            PredictiveSearch.attachResultsToDom(predResults.html);

            if (!PredictiveSearch.state.modalOpen) {
                PredictiveSearch.showDropdown();
                PredictiveSearch.bindCloseDropdown();
                PredictiveSearch.bindArrows();
            }

        },

        resultsFail: function resultsFail (predFail) {

            console.log(predFail);
            console.log(predFail.stack);
            PredictiveSearch.hideDropdown();

        },

        buildResultsCall: function buildResultsCall (searchTerm) {

            // If searchTerm is empty, close modal and reset everything
            if (!config.rfkApiEnabled && flx.util.isEmpty(searchTerm)) {
                PredictiveSearch.hideDropdown();
                return null;
            }

            var encodedTerm = searchTerm ? encodeURIComponent(searchTerm) : '';
            var resultsCall = $.ajax({
                url: window.location.origin + '/pred-search/' + encodedTerm,
                method: 'GET',
                type: 'json'
            });

            return resultsCall;

        },

        showDropdown: function showDropdown () {
            $(config.targets.predSearchWrap).addClass(config.activeClass);
            $(config.targets.predSearchContainer).show();
            PredictiveSearch.state.modalOpen = true;
        },

        hideDropdown: function hideDropdown () {
            $(config.targets.predSearchWrap).removeClass(config.activeClass);
            $(config.targets.predSearchContainer).hide();
            PredictiveSearch.removeResultsFromDom();
            PredictiveSearch.unbindCloseDropdown();
            PredictiveSearch.unbindArrows();
            PredictiveSearch.unbindSuggestChoice();
            PredictiveSearch.resetResultIndex();
            PredictiveSearch.state.modalOpen = false;
            PredictiveSearch.state.lastInput = null;
        },

        attachResultsToDom: function attachResultsToDom (results) {
            $(config.targets.predSearchContainer).html(results);
            PredictiveSearch.getResultIndex();
        },

        removeResultsFromDom: function removeResultsFromDom () {
            $(config.targets.predSearchContainer).html();
        }

    };

    /*----------[Module Main]----------*/

    /**
     * Load things after feature flags have loaded
     */
    $(document).on(
        flx.util.events.REMOTE_FLAGS_LOADED,
        function ($event, featureFlagsBackEndPlugin) {

            if (config.reflektionEnabled) {

                if (featureFlagsBackEndPlugin.flags.reflektionApi) {
                    // predictive-search-handler will automatically run the RFK API-specific logic instead of default
                    config.rfkApiEnabled = true;
                    PredictiveSearch.init();
                    return;
                }

                // Reflektion uses focus/blur for showing/hiding their search recommendations panel regardless of input value
                $(config.targets.predSearchInput).on('focus', function () {
                    $(config.targets.predSearchWrap).addClass(config.activeClass);
                });
                $(config.targets.predSearchInput).on('blur', function () {
                    $(config.targets.predSearchWrap).removeClass(config.activeClass);
                });

            } else {

                // Only run for desktop as it was originally only implemented there. We only show mobile version for
                // RFK setup but new RFK API implementation requires use of this logic as handled above when enabled.
                if (config.deviceType === 'desktop') {
                    // Our standard search recommendations
                    PredictiveSearch.init();
                }

            }
        }
    );

})();
