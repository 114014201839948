(function () {

    // Location.origin polyfill
    if (!window.location.origin) {
        window.location.origin = window.location.protocol + '//'
            + window.location.hostname
            + (window.location.port ? ':' + window.location.port : '');
    }

    // isNan Polyfill
    Number.isNaN = Number.isNaN || function (value) {
        return typeof value === 'number' && isNaN(value);
    };

    // isInteger Polyfill
    Number.isInteger = Number.isInteger || function (value) {
        return typeof value === 'number'
            && isFinite(value)
            && Math.floor(value) === value;
    };

    // toISOString Polyfill
    if (!Date.prototype.toISOString) {
        (function () {

            function pad (number) {
                if (number < 10) {
                    return '0' + number;
                }
                return number;
            }

            Date.prototype.toISOString = function () {
                return this.getUTCFullYear() +
                '-' + pad(this.getUTCMonth() + 1) +
                '-' + pad(this.getUTCDate()) +
                'T' + pad(this.getUTCHours()) +
                ':' + pad(this.getUTCMpolyinutes()) +
                ':' + pad(this.getUTCSeconds()) +
                '.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
                'Z';
            };

        }());
    };

})();
