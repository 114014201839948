(function () {

    /*----------[Module Configuration]----------*/

    var config = {
        targets: {
            deviceInfoData: '.js-deviceInfoData'
        },
        accessKeys: {
            cookie: 'flxUserGuid',
            localStorage: 'flxUserGuid'
        },
        timeouts: {
            cookieInDays: 10000
        }
    };

    /*----------[Module Objects]----------*/

    var DeviceInfo = flx.deviceInfo = {

        // ________ Properties ________ //

        state: {
            isLocalStorageCapable: false,
            uaData: {},
            deviceIdentifier: null
        },

        // ________ Methods ________ //

        /**
         * Initizializes the DeviceInfo core module
         *
         * NOTES:
         *    - Initializes the state object
         */
        init: function init () {
            DeviceInfo.initState();
        },

        /**
         * Initizializes the DeviceInfo state object
         *
         * NOTES:
         *    - Initializes bool indicating if we are using a localStorage capable browser
         *    - Initializes uaData that is passed from server-side
         *    - Initializes the device identifier and instantiates it if not previously created
         */
        initState: function initState () {

            DeviceInfo.state.isLocalStorageCapable = flx.util.hasStorageCapability('localStorage');
            DeviceInfo.state.uaData = flx.util.extractJsonFromScript(config.targets.deviceInfoData);
            DeviceInfo.state.deviceIdentifier = DeviceInfo.instantiateDeviceIdentifier();

        },

        /**
         * User-agent string getter method
         */
        getUserAgentString: function getUserAgentString () {
            return DeviceInfo.state.uaData.source;
        },

        /**
         * Calculates and returns the current innerHeight value
         */
        calcHeight: function calcHeight () {
            return window.innerHeight;
        },

        /**
         * Calculates and returns the current innerWidth value
         */
        calcWidth: function calcWidth () {
            return window.innerWidth;
        },

        /**
         * Builds height and width string from the current browser dimensions
         *
         * NOTES:
         *      - The string is formatted as `${width}x${height}`
         */
        getHeightWidthString: function getHeightWidthString () {
            return DeviceInfo.calcWidth() + 'x' + DeviceInfo.calcHeight();
        },

        /**
         * Operating system getter method
         */
        getOperatingSys: function getOperatingSys () {
            return DeviceInfo.state.uaData.os;
        },

        /**
         * Device type getter method
         */
        getDeviceType: function getDeviceType () {
            return DeviceInfo.state.uaData.deviceBundleType;
        },

        /**
         * Gets the make of the user's device
         *
         * NOTES:
         *      - This would show up as something like "Apple Mac"
         */
        getDeviceMake: function getDeviceMake () {
            return DeviceInfo.state.uaData.platform;
        },

        /**
         * Device model getter method
         *
         * NOTES:
         *      - The supported model-types are: Apple, Samsung, Android, Blackberry and Kindle
         *      - If the device isn't a supported model, it defaults to 'Unknown <deviceType>'
         */
        getDeviceModel: function getDeviceModel () {

            var modelMatched = false;
            var deviceModel = 'Unknown ' + DeviceInfo.getDeviceType();
            var uaData = DeviceInfo.state.uaData;

            // Apple
            if (uaData.isMac || uaData.isiPad || uaData.isiPod || uaData.isiPhone) {
                modelMatched = true;
                deviceModel = 'Apple';
            }

            // Samsung
            if (!modelMatched && uaData.isSamsung) {
                modelMatched = true;
                deviceModel = 'Samsung';
            }

            // Generic Android
            if (!modelMatched && uaData.isAndroid) {
                modelMatched = true;
                deviceModel = 'Android';
            }

            // Blackberry
            if (!modelMatched && uaData.isBlackberry) {
                modelMatched = true;
                deviceModel = 'Blackberry';
            }

            // Kindle
            if (!modelMatched && uaData.isKindleFire) {
                modelMatched = true;
                deviceModel = 'Kindle';
            }

            return deviceModel;

        },

        /**
         * Creates unique deviceIdentifier and stores it in state object, localStorage and cookies
         *
         * @returns {String} deviceIdentifier - unique identifier tied to a device and browser
         *
         * NOTES:
         *      - There's a waterfall mechanism on the storage that only kicks in when the user's
         *        info isn't already stored somewhere:
         *          (1) State object
         *          (2) localStorage
         *          (3) Cookies
         *
         *      - If the identifier is already stored somewhere, we will only save it in the places
         *        where it wasn't found (i.e. if no cookie was present, we will write the cookie
         *        with the data we extracted from a localStorage key)
         */
        instantiateDeviceIdentifier: function instantiateDeviceIdentifier () {

            // Access keys
            var cookieKey = config.accessKeys.cookie;
            var localStorageKey = config.accessKeys.localStorage;
            var cookieTimeout = config.timeouts.cookieInDays;

            // Extract identifier information
            var identifierStatus = DeviceInfo.getDeviceIdentifier(true);
            var deviceIdentifier = identifierStatus.deviceIdentifier;

            // Status codes
            var inState = identifierStatus.statuses.inState;
            var inLocalStorage = identifierStatus.statuses.inLocalStorage;
            var inCookie = identifierStatus.statuses.inCookie;

            // If not in state object, add it
            if (!inState) {
                DeviceInfo.state.deviceIdentifier = deviceIdentifier;
            }

            // If not in localStorage, add it
            if (!inLocalStorage && DeviceInfo.state.isLocalStorageCapable) {
                localStorage.setItem(localStorageKey, deviceIdentifier);
            }

            // If not in cookie, add it
            if (!inCookie) {
                flx.util.setCookie(cookieKey, deviceIdentifier, cookieTimeout);
            }

            return deviceIdentifier;

        },

        /**
         * Gets deviceIdentifier or generates a fresh one when not cached anywhere
         *
         * @param {Boolean} shouldReturnStatus - if true, we return the status obj, otherwise string
         * @returns {String || Object} deviceIdentifier || identifierStatus - id or info obj
         *
         * NOTES:
         *      - This method allows us to get an identifier extracted from the identifier caching
         *        waterfall. If it is not cached anywhere (not in state/localStorage/cookie), then
         *        we generate an id on the fly and return it instead
         *
         *      - If shouldReturnStatus is passed-in as true, then we return an identifierStatus
         *        object which informs as to if/where the user's device identifier might already
         *        be stored. Using this, we can cache across the entire caching waterfall where
         *        the value is missing
         */
        getDeviceIdentifier: function getDeviceIdentifier (shouldReturnStatus) {

            // Init vars
            var localStorageKey = config.accessKeys.localStorage;
            var cookieKey = config.accessKeys.cookie;

            // Fetch data from localStorage and cookie for deviceIdentifier waterfall
            var localStorageDeviceId = (DeviceInfo.state.isLocalStorageCapable)
                ? localStorage.getItem(localStorageKey)
                : null;

            var stateDeviceId = DeviceInfo.state.deviceIdentifier;
            var cookieDeviceId = flx.util.getCookie(cookieKey);

            // Prioritize id from: (1) state object, (2) localStorage, (3) cookie
            var deviceIdentifier = stateDeviceId || localStorageDeviceId || cookieDeviceId;

            // Build the status object based on what data was available for extraction
            var identifierStatus = {
                deviceIdentifier: deviceIdentifier || flx.util.generateQuickUuid(),
                statuses: {
                    inState: flx.util.isNotEmpty(stateDeviceId),
                    inLocalStorage: flx.util.isNotEmpty(localStorageDeviceId),
                    inCookie: flx.util.isNotEmpty(cookieDeviceId)
                }
            };

            return (shouldReturnStatus) ? identifierStatus : identifierStatus.deviceIdentifier;

        },

        /**
         * Clears deviceIdentifier from all storage mechanisms (i.e. state/localStorage/cookie)
         */
        clearDeviceIdentifier: function clearDeviceIdentifier () {

            // Remove from state obj cache, local storage and cookie
            DeviceInfo.state.deviceIdentifier = null;
            flx.util.deleteCookie(config.accessKeys.cookie);

            if (DeviceInfo.state.isLocalStorageCapable) {
                localStorage.removeItem(config.accessKeys.localStorage);
            }

        },

        /**
         * Clears deviceIdentifier, then re-instantiates it across all storage mechanisms
         */
        refreshDeviceIdentifier: function refreshDeviceIdentifier () {

            // Clear the existing identifier
            DeviceInfo.clearDeviceIdentifier();

            // Set a new identifier
            return DeviceInfo.instantiateDeviceIdentifier();

        }

    };

    /*----------[Module Main]----------*/

    // Initialize the module
    DeviceInfo.init();

})();
