// Search Bar Outline
// TODO: Make this reusable
(function () {

    var $searchBar = $('.searchBar');
    var $searchField = $('.searchField');
    var dirtyClassName = 'dirty';

    $searchField.on({
        focus: function () {
            $searchBar.addClass('focused');
        },
        blur: function () {
            if (!$searchField.val()) {
                $searchBar.removeClass('focused');
            }
        },
        change: function () {
            var $this = $(this);

            if ($this.val().length > 0 && !$searchBar.hasClass(dirtyClassName)) {
                $searchBar.addClass(dirtyClassName);
            } else if ($searchBar.hasClass(dirtyClassName)) {
                $searchBar.removeClass(dirtyClassName);
            }
        }
    });

})();
