(function () {

    // Load search page on search bar form submission
    $('form[name=searchBar]').submit(function (e) {
        e.preventDefault();
        var searchText = $('input[name=searchInput]').val();

        // prevent redirect when search field is empty
        if (flx.util.isNotEmpty(searchText)) {
            var url = window.location.origin + '/search/' + encodeURIComponent(searchText);
            window.location = url;
        }
    });

    // On mobile device, toggle header search bar
    $('#searchBar').on({
        click: function (e) {
            e.preventDefault();
            var $mobileSearchBar = $('#site-search');
            $mobileSearchBar.toggle();
        }
    });

})();
